<section class="recording__container">
  <div>
    <h1>Data recording</h1>
    <p>
      Record data and receive them in YourDrive:Users/yourusername/Downloads and
      YourDrive:Users/yourusername/Documents/NeuroUX <br>
      Console app is not required if you do not have Eye Tracking, Mouse And Keyboard, Screen Recording checked.<br>
      Face recording must be active if you want to receive a project data file(events_data.csv).
    </p>
  </div>

  <div style="display: flex;">
    <mat-card>
      <mat-checkbox class="unselectable" *ngFor="let setting of userSettingsAsArray; let i = index"
        [checked]="userSettings[userSettingsAsArray[i]]" [disabled]="isRecording"
        (change)="toggleSettings(setting)">{{formatStringToTitleCase(setting)}}</mat-checkbox>
    </mat-card>

    <app-console-app-connector style="display: flex; align-items: center;" [isRecording]="isRecording" />

  </div>

  <ng-container *ngIf="this.consoleAppReady || !this.consoleAppRequired()">
    <mat-card *ngIf="userSettings.muse" flex-direction="row" class="my-gaze__actions">
      <button *ngIf="!museConnected" mat-raised-button color="primary" (click)="connectMuse()">{{museConnecting ?
        "Connecting Muse" : "Connect Muse"}}
        <mat-icon>bluetooth</mat-icon>
      </button>

      <button *ngIf="museConnected" mat-raised-button color="primary" (click)="disconnect()">Disconnect Muse
        <mat-icon>bluetooth_connected</mat-icon>
      </button>

      <span class="battery-level-indicator" *ngIf="batteryLevelDataAvailable">
        {{batteryLevel|number:"1.0-0"}}% <mat-icon>battery_std</mat-icon>
      </span>

    </mat-card>

    <mat-card *ngIf="readyToRecord || isRecording">

      <mat-card-title *ngIf="readyToRecord">To {{isRecording ?
        "stop"
        : "start"}} recording press
        {{recordHotkey}}</mat-card-title>
      <mat-card-title *ngIf="isRecording">Recording...</mat-card-title>

      <mat-card-content>
        <screen-webcam-recorder></screen-webcam-recorder>
        <eeg-recorder [data]="data" *ngIf="userSettings.muse && museReady"></eeg-recorder>
      </mat-card-content>

    </mat-card>

    <mat-card id="container" *ngIf="userSettings.faceRecording || data">
      <app-webcam-emotion-recognition *ngIf="userSettings.faceRecording"></app-webcam-emotion-recognition>
      <div id="indicatorDiv" *ngIf="data">
      </div>
    </mat-card>

    <mat-card *ngIf="data && userSettings.muse ">
      <app-time-series [data]="data"></app-time-series>
      <app-sensor-visualizer [data]="data"></app-sensor-visualizer>
    </mat-card>
  </ng-container>
</section>

import { Injectable } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { BehaviorSubject } from 'rxjs';

// scoped service for providing video data for children of video components
@Injectable({ providedIn: 'root' })
export class VideoService {
  private _api = new BehaviorSubject<VgApiService | null>(null);
  private width = new BehaviorSubject<number>(0);
  private height = new BehaviorSubject<number>(0);

  readonly api = this._api.asObservable();
  readonly videoWidth = this.width.asObservable();
  readonly videoHeight = this.height.asObservable();

  getApi() {
    return this._api.value;
  }

  setApi(api: VgApiService) {
    this._api.next(api);
  }

  getWidth() {
    return this.width.value;
  }

  getHeight() {
    return this.height.value;
  }

  setWidth(width: number) {
    if (width <= 0 || width === this.width.value) return false;
    this.width.next(width);
  }

  setHeight(height: number) {
    if (height <= 0 || height === this.height.value) return false;
    this.height.next(height);
  }

}

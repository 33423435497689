import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import CanvasDrawn from './canvas-drawn-model-class';
import { VideoService } from '../services/video.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-redrawing-canvas',
  template: '<canvas #canvasElement [width]="width" [height]="height" style="position: absolute; height: 100%; width: 100%;" ></canvas>',
})
export class RedrawingCanvasComponent implements AfterViewInit, OnDestroy {

  // internal canvas pixels don't actually scale automatically, so we need to scale the canvas context
  width = 0;
  height = 0;

  videoAPI?: VgApiService;
  private subscriptions: Subscription;
  private resizeObserver: ResizeObserver;

  private _canvasDrawn: CanvasDrawn[] = [];
  @Input()
  get canvasDrawn(): CanvasDrawn[] {
    return this._canvasDrawn;
  }
  set canvasDrawn(value: CanvasDrawn[]) {
    this._canvasDrawn = value;
    console.log('redrawing canvas', this._canvasDrawn);
    this.scheduleRedraw();
  }

  @ViewChild('canvasElement') canvas: ElementRef<HTMLCanvasElement>;
  private context: CanvasRenderingContext2D;

  @Output() onShapeDecay = new EventEmitter<CanvasDrawn[]>();

  constructor(private videoService: VideoService) {
    this.subscriptions = new Subscription();
    this.subscriptions.add(videoService.api.subscribe((api) => {
      this.videoAPI = api;
    }));
  };

  ngAfterViewInit() {
    this.context = this.canvas.nativeElement.getContext('2d');
    this.resizeObserver = new ResizeObserver(() => this.scheduleRedraw());
    this.resizeObserver.observe(this.canvas.nativeElement);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.resizeObserver.disconnect();

    this.subscriptions = null;
    this.resizeObserver = null;
  }

  private scheduleRedraw() {
    return requestAnimationFrame(() => this.reDrawCanvas());
  }

  private reDrawCanvas() {
    if (!this.canvasDrawn.length || !this.context) return;
    const canvasWidth = Number(this.canvas.nativeElement.offsetWidth);
    const canvasHeight = Number(this.canvas.nativeElement.offsetHeight);

    if (canvasWidth !== this.width && !isNaN(canvasWidth) && canvasHeight !== this.height && !isNaN(canvasHeight)) {
      this.width = canvasWidth;
      this.height = canvasHeight;
    }

    const videoWidth = this.videoService.getWidth();
    const videoHeight = this.videoService.getHeight();

    const scaleX = canvasWidth / videoWidth;
    const scaleY = canvasHeight / videoHeight;
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);

    // save and scale the context as the coordinates for elements is based on the actual video file size, and the videoplayer/canvas size is based on screen size
    this.context.save();
    this.context.scale(scaleX, scaleY);
    this.canvasDrawn.forEach((element) => element.draw(this.context));
    this.context.restore();
  }
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VideoService } from '../services/video.service';
import { CSVRecordKeyboardEvent } from '../shared/types/csv-model';
import { Subscription } from 'rxjs';
import { VGApiServiceEx } from '../shared/types/VGApiService';
import { toFixedNumber } from '../utils';

@Component({
  selector: 'app-keyboard-present',
  templateUrl: './keyboard-present.component.html',
})
export class KeyboardPresentComponent implements AfterViewInit, OnDestroy {
  // kinda just partial copypasta, need to figure out how to improve
  @Input() keyboardEvents: CSVRecordKeyboardEvent[];

  displayString = '';

  private subscriptions: Subscription;
  private api: VGApiServiceEx;
  private timer: NodeJS.Timeout;

  once = false;

  public get currentTime(): number {
    if (!this.api) return 0;
    return this.api.currentTime;
  }

  constructor(private videoService: VideoService) {

  }

  ngAfterViewInit() {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.videoService.api.subscribe((api) => this.hookToApi(api)));
  }

  private hookToApi(api: VGApiServiceEx) {
    if (!api) return;
    this.api = api;

    this.subscriptions.add(this.api.subscriptions.play.subscribe(() => this.onPlayVideo()));
    this.subscriptions.add(this.api.subscriptions.pause.subscribe(() => this.onPause()));
  }

  private onPlayVideo() {
    // looking up all of them each time is not performant, may be worth checking about optimization
    if (!this.keyboardEvents.length) return;
    // console.log(this.keyboardEvents);
    this.loopThroughEvents();
  }

  private loopThroughEvents() {
    if (!this.once) {
      console.log(this.keyboardEvents);
      this.once = true;
    }

    const nextTrackEvent = this.keyboardEvents.find(eventRecord => {
      console.log(Number(eventRecord.timeColumn), toFixedNumber(this.currentTime, 3), Number(eventRecord.timeColumn) >= toFixedNumber(this.currentTime, 3), eventRecord.buttonNameColumn);
      return Number(eventRecord.timeColumn) >= toFixedNumber(this.currentTime, 3);
    });

    if (!nextTrackEvent) return;
    const msToNextEvent = (Number(nextTrackEvent.timeColumn) - toFixedNumber(this.currentTime, 3)) * 1000;

    console.log("msToNextEvent", msToNextEvent, "nextTrackEvent.timeColumn", nextTrackEvent.timeColumn, "this.currentTime", this.currentTime);

    this.timer = setTimeout(() => {
      console.log("time reached", this.currentTime, "should be", nextTrackEvent.timeColumn);

      // nextTrackEvent.eventShown = true;
      this.displayString = this.makeDisplayString(nextTrackEvent);
      this.onPlayVideo();
    }, msToNextEvent);
  }

  private onPause() {
    this.endTimer();
  }

  private endTimer() {
    clearTimeout(this.timer);
    this.timer = null;
  }

  private makeDisplayString(event: CSVRecordKeyboardEvent) {
    return `Last keyboard event at ${event.timeColumn}, button ${event.buttonNameColumn} and state: ${event.stateColumn}`;
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.subscriptions = null;

    this.endTimer();

  }

}


import { FileInputComponent } from '../file-input/file-input.component';
import { FileDataType } from '../file-input/recorded-data-enums';
import { getDefaultFileName } from '../file-input/request-files';
import { emotionFileSynced, museFileSynced, projectDataFile } from '../file-input/request-files-presets';
import { ProcessEmotionsFileComponent } from '../process-emotions-file/process-emotions-file.component';
import { ProcessMuseFileComponent } from '../process-muse-file/process-muse-file.component';
import { MuseBaselineData, MuseAverages, AnalyzedMuseDataPerSecond, ColumnAveragesPerTask, Task } from '../shared/types/csv-model';
import { TaskTimes } from './../process-project-data-file/process-project-data-file.component';
import { Component } from '@angular/core';
import { Workbook } from 'exceljs';

@Component({
  selector: 'app-clean-data',
  templateUrl: './clean-data.component.html',
  styleUrls: ['./clean-data.component.scss']
})
export class CleanDataComponent {
  taskTimes: TaskTimes = new TaskTimes();
  valuesPerSecondMuse: ProcessMuseFileComponent["analyzedMuseData"] = [];
  valuesPerSecondEmotions: ProcessEmotionsFileComponent["analyzedData"] = [];
  baselineValuesArr: number[][] = [];
  museValuesPerTask: MuseValuesPerTask;
  museValuesPerTaskArr: MuseValuesPerTask[];
  emotionValuesPerTask: EmotionValuesPerTask;
  emotionValuesPerTaskArr: EmotionValuesPerTask[];
  baseLineAverages = new MuseAverages();
  analyzedDataPerSecond = new AnalyzedMuseDataPerSecond();
  analyzedDataPerSecondArr: AnalyzedMuseDataPerSecond[] = [];
  columnAveragesPerTask = new ColumnAveragesPerTask();
  task = new Task();
  analyzedData: Task[] = [];
  emotionsDataWasUploaded = false;

  public requestInputs = FileDataType.ProjectData | FileDataType.EmotionDataSynced | FileDataType.MuseDataSynced;
  public requestStrings: FileInputComponent["uploadText"] = {
    [FileDataType.ProjectData]: `Upload ${getDefaultFileName(projectDataFile)} Project Data file`,
    [FileDataType.EmotionDataSynced]: `Upload ${getDefaultFileName(emotionFileSynced)} emotion_synced file to analyze emotions data`,
    [FileDataType.MuseDataSynced]: `Upload ${getDefaultFileName(museFileSynced)} muse_synced file to analyze data from Muse`
  };

  public timesEvent(value: TaskTimes) {
    this.taskTimes = value;
  }

  public sendEmotionsDataEvent(value: CleanDataComponent["valuesPerSecondEmotions"]) {
    this.valuesPerSecondEmotions = value;
    this.emotionsDataWasUploaded = true;
    this.separateEmotionsDataPerTask();
  }

  public sendMuseDataEvent(value: CleanDataComponent["valuesPerSecondMuse"]) {
    this.valuesPerSecondMuse = value;
    this.separateMuseDataPerTask();
    this.analyzeDataPerTask();
  }

  separateEmotionsDataPerTask() {
    let taskNumber = 1;
    this.emotionValuesPerTask = new EmotionValuesPerTask();
    this.emotionValuesPerTaskArr = [];
    this.emotionValuesPerTask.taskValuesEmotion = [];
    for (let i = 0; i < this.taskTimes.taskTimesArr.length; i++) {
      let startTime = this.taskTimes.taskTimesArr[i].startTime;
      let endTime = this.taskTimes.taskTimesArr[i].endTime;
      this.emotionValuesPerTask.taskNumber = taskNumber;

      for (let j = 0; j < this.valuesPerSecondEmotions.length; j++) {
        if (Number(this.valuesPerSecondEmotions[j][1]) >= startTime && Number(this.valuesPerSecondEmotions[j][1]) <= endTime) {
          this.emotionValuesPerTask.taskValuesEmotion.push(this.valuesPerSecondEmotions[j]);
        }

      }
      this.emotionValuesPerTaskArr.push(this.emotionValuesPerTask);
      startTime = null;
      endTime = null;
      this.emotionValuesPerTask = new EmotionValuesPerTask();
      this.emotionValuesPerTask.taskValuesEmotion = [];
      taskNumber++;

    }
  }

  separateMuseDataPerTask() {
    /*
        this.taskTimes.baseline.startTime;
        this.taskTimes.baseline.endTime;
    */

    for (let i = 0; i < this.valuesPerSecondMuse.length; i++) {
      if (this.valuesPerSecondMuse[i][0] >= this.taskTimes.baseline.startTime && this.valuesPerSecondMuse[i][0] <= this.taskTimes.baseline.endTime) {
        this.baselineValuesArr.push(this.valuesPerSecondMuse[i]);
      }
    }

    this.baseLineAverages = this.calculateBaseline();

    let taskNumber = 1;
    this.museValuesPerTask = new MuseValuesPerTask();
    this.museValuesPerTaskArr = [];
    this.museValuesPerTask.taskValuesMuse = [];

    for (let i = 0; i < this.taskTimes.taskTimesArr.length; i++) {
      let startTime = this.taskTimes.taskTimesArr[i].startTime;
      let endTime = this.taskTimes.taskTimesArr[i].endTime;

      for (let j = 0; j < this.valuesPerSecondMuse.length; j++) {
        if (this.valuesPerSecondMuse[j][0] >= startTime && this.valuesPerSecondMuse[j][0] <= endTime) {
          if (this.valuesPerSecondMuse[j][0] === startTime) {
            this.museValuesPerTask.taskNumber = taskNumber;
            taskNumber++;
          }

          this.museValuesPerTask.taskValuesMuse.push(this.valuesPerSecondMuse[j]);

          if (this.valuesPerSecondMuse[j][0] === endTime) {
            this.museValuesPerTaskArr.push(this.museValuesPerTask);

            if (!this.museValuesPerTask.taskNumber) {
              this.museValuesPerTask.taskNumber = taskNumber;
              taskNumber++;
            }

            startTime = null;
            endTime = null;
            this.museValuesPerTask = new MuseValuesPerTask();
            this.museValuesPerTask.taskValuesMuse = [];
          }
        }
      }
    }
  }

  calculateBaseline(): MuseAverages {
    const baselineData = new MuseBaselineData();
    for (let i = 0; i < this.baselineValuesArr.length; i++) {
      baselineData.deltaTP9.push(this.baselineValuesArr[i][1]);
      baselineData.deltaAF7.push(this.baselineValuesArr[i][2]);
      baselineData.deltaAF8.push(this.baselineValuesArr[i][3]);
      baselineData.deltaTP10.push(this.baselineValuesArr[i][4]);

      baselineData.thetaTP9.push(this.baselineValuesArr[i][5]);
      baselineData.thetaAF7.push(this.baselineValuesArr[i][6]);
      baselineData.thetaAF8.push(this.baselineValuesArr[i][7]);
      baselineData.thetaTP10.push(this.baselineValuesArr[i][8]);

      baselineData.alphaTP9.push(this.baselineValuesArr[i][9]);
      baselineData.alphaAF7.push(this.baselineValuesArr[i][10]);
      baselineData.alphaAF8.push(this.baselineValuesArr[i][11]);
      baselineData.alphaTP10.push(this.baselineValuesArr[i][12]);

      baselineData.betaTP9.push(this.baselineValuesArr[i][13]);
      baselineData.betaAF7.push(this.baselineValuesArr[i][14]);
      baselineData.betaAF8.push(this.baselineValuesArr[i][15]);
      baselineData.betaTP10.push(this.baselineValuesArr[i][16]);

      baselineData.gammaTP9.push(this.baselineValuesArr[i][17]);
      baselineData.gammaAF7.push(this.baselineValuesArr[i][18]);
      baselineData.gammaAF8.push(this.baselineValuesArr[i][19]);
      baselineData.gammaTP10.push(this.baselineValuesArr[i][20]);
    }

    return this.getAverages(baselineData);

  }

  getAverages(baselineData: MuseBaselineData): MuseAverages {
    const averages = new MuseAverages();

    averages.deltaTP9 = this.calculateAverage(baselineData.deltaTP9);
    averages.deltaAF7 = this.calculateAverage(baselineData.deltaAF7);
    averages.deltaAF8 = this.calculateAverage(baselineData.deltaAF8);
    averages.deltaTP10 = this.calculateAverage(baselineData.deltaTP10);

    averages.thetaTP9 = this.calculateAverage(baselineData.thetaTP9);
    averages.thetaAF7 = this.calculateAverage(baselineData.thetaAF7);
    averages.thetaAF8 = this.calculateAverage(baselineData.thetaAF8);
    averages.thetaTP10 = this.calculateAverage(baselineData.thetaTP10);

    averages.alphaTP9 = this.calculateAverage(baselineData.alphaTP9);
    averages.alphaAF7 = this.calculateAverage(baselineData.alphaAF7);
    averages.alphaAF8 = this.calculateAverage(baselineData.alphaAF8);
    averages.alphaTP10 = this.calculateAverage(baselineData.alphaTP10);

    averages.betaTP9 = this.calculateAverage(baselineData.betaTP9);
    averages.betaAF7 = this.calculateAverage(baselineData.betaAF7);
    averages.betaAF8 = this.calculateAverage(baselineData.betaAF8);
    averages.betaTP10 = this.calculateAverage(baselineData.betaTP10);

    averages.gammaTP9 = this.calculateAverage(baselineData.gammaTP9);
    averages.gammaAF7 = this.calculateAverage(baselineData.gammaAF7);
    averages.gammaAF8 = this.calculateAverage(baselineData.gammaAF8);
    averages.gammaTP10 = this.calculateAverage(baselineData.gammaTP10);

    return averages;

  }

  calculateAverage(list: number[]) {
    let sum = 0;

    for (let i = 0; i < list.length; i++) {
      sum += list[i];
    }
    const average = sum / list.length;

    return average;
  }

  analyzeDataPerTask() {
    // 1) averagePerSecond
    // 2) attention - deltaTP9, deltaAF7, deltaAF8, deltaTP10 in %: (delta per channel - baseline per channel) / baseline per channel
    // 3) attention averages in %
    // 4) arousal - gammaTP9, gammaAF7, gammaAF8, gammaTP10 in %: (gamma per channel - baseline per channel) / baseline per channel
    // 5) arousal averages in %
    // 6) avoid - alfaTP10-alfaTP9: greater than 0;
    // 7) approach - alfaTP10-alfaTP9: less than 0;

    const bl = this.baseLineAverages;

    const blDTP9 = bl.deltaTP9;
    const blDAF7 = bl.deltaAF7;
    const blDAF8 = bl.deltaAF8;
    const blDTP10 = bl.deltaTP10;

    const blGTP9 = bl.gammaTP9;
    const blGAF7 = bl.gammaAF7;
    const blGAF8 = bl.gammaAF8;
    const blGTP10 = bl.gammaTP10;

    let attAvgArr = [];
    let aroAvgArr = [];
    let avoidArr = [];
    let approachArr = [];

    this.museValuesPerTaskArr.forEach((task) => {
      this.task.taskNumber = task.taskNumber;

      for (let i = 0; i < task.taskValuesMuse.length; i++) {
        this.analyzedDataPerSecond.attentionDeltaTP9 = this.calculatePercent(blDTP9, task.taskValuesMuse[i][1]);
        this.analyzedDataPerSecond.attentionDeltaAF7 = this.calculatePercent(blDAF7, task.taskValuesMuse[i][2]);
        this.analyzedDataPerSecond.attentionDeltaAF8 = this.calculatePercent(blDAF8, task.taskValuesMuse[i][3]);
        this.analyzedDataPerSecond.attentionDeltaTP10 = this.calculatePercent(blDTP10, task.taskValuesMuse[i][4]);
        this.analyzedDataPerSecond.attentionAverages = ((this.analyzedDataPerSecond.attentionDeltaTP9 + this.analyzedDataPerSecond.attentionDeltaTP10 + this.analyzedDataPerSecond.attentionDeltaAF7 + this.analyzedDataPerSecond.attentionDeltaAF8) / 4);

        attAvgArr.push(this.analyzedDataPerSecond.attentionAverages);

        this.analyzedDataPerSecond.arousalGammaTP9 = this.calculatePercent(blGTP9, task.taskValuesMuse[i][17]);
        this.analyzedDataPerSecond.arousalGammaAF7 = this.calculatePercent(blGAF7, task.taskValuesMuse[i][18]);
        this.analyzedDataPerSecond.arousalGammaAF8 = this.calculatePercent(blGAF8, task.taskValuesMuse[i][19]);
        this.analyzedDataPerSecond.arousalGammaTP10 = this.calculatePercent(blGTP10, task.taskValuesMuse[i][20]);
        this.analyzedDataPerSecond.arousalAverages = ((this.analyzedDataPerSecond.arousalGammaTP9 + this.analyzedDataPerSecond.arousalGammaTP10 + this.analyzedDataPerSecond.arousalGammaAF7 + this.analyzedDataPerSecond.arousalGammaAF8) / 4);

        aroAvgArr.push(this.analyzedDataPerSecond.arousalAverages);

        this.analyzedDataPerSecond.avoid = task.taskValuesMuse[i][12] - task.taskValuesMuse[i][9];
        avoidArr.push(this.analyzedDataPerSecond.avoid);
        this.analyzedDataPerSecond.approach = task.taskValuesMuse[i][12] - task.taskValuesMuse[i][9];
        approachArr.push(this.analyzedDataPerSecond.approach);

        this.analyzedDataPerSecond.timeAsSeconds = task.taskValuesMuse[i][0];

        this.analyzedDataPerSecondArr.push(this.analyzedDataPerSecond);
        this.analyzedDataPerSecond = new AnalyzedMuseDataPerSecond();
      }

      this.columnAveragesPerTask.averageOfAttentionAverages = this.calculateAverage(attAvgArr);
      this.columnAveragesPerTask.averageOfArousalAverages = this.calculateAverage(aroAvgArr);
      this.columnAveragesPerTask.avoidAverages = this.calculateAverage(avoidArr);
      this.columnAveragesPerTask.approachAverages = this.calculateAverage(approachArr);

      this.task.secondBasedData = this.analyzedDataPerSecondArr;
      this.task.averages = this.columnAveragesPerTask;
      this.analyzedData.push(this.task);

      attAvgArr = [];
      aroAvgArr = [];
      avoidArr = [];
      approachArr = [];
      this.columnAveragesPerTask = new ColumnAveragesPerTask();
      this.analyzedDataPerSecondArr = [];
      this.task = new Task();


    });

    this.exportAsExcel();

  }

  calculatePercent(baselineAverage: number, channelAverage: number) {
    const bl = baselineAverage;
    const cl = channelAverage;

    /*let value: number = (((cl-bl) / bl)*100);
    let percent: number = Math.round(value);
    return percent;*/

    const value: number = ((cl - bl) / bl);
    return value;

  }

  async exportAsExcel() {
    const workbook = new Workbook();

    workbook.views = [
      {
        x: 0, y: 0, width: 10000, height: 20000,
        firstSheet: 0, activeTab: 1, visibility: 'visible'
      }
    ];

    if (!this.analyzedData.length) {
      workbook.addWorksheet('No Data');
    } else {
      this.analyzedData.forEach(function (task) {
        workbook.addWorksheet('Task' + task.taskNumber);
      });
      for (let i = 0; i < workbook.worksheets.length; i++) {
        this.addSheetData(workbook, i + 1);
      }
    }

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'muse_analyzed.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });

  }

  convertToPercentages(column) {
    column.eachCell(function (cell) {
      cell.numFmt = '0%';
    });
  }

  addSheetData(workbook: Workbook, sheetID: number) {
    const sheet = workbook.getWorksheet(sheetID);
    sheet.columns = [
      { header: 'Time', key: 'timeAsSecondsMuse' },
      { header: 'delta_TP9', key: 'delta_TP9' },
      { header: 'delta_AF7', key: 'delta_AF7' },
      { header: 'delta_AF8', key: 'delta_AF8' },
      { header: 'delta_TP10', key: 'delta_TP10' },
      { header: 'theta_TP9', key: 'theta_TP9' },
      { header: 'theta_AF7', key: 'theta_AF7' },
      { header: 'theta_AF8', key: 'theta_AF8' },
      { header: 'theta_TP10', key: 'theta_TP10' },
      { header: 'alpha_TP9', key: 'alpha_TP9' },
      { header: 'alpha_AF7', key: 'alpha_AF7' },
      { header: 'alpha_AF8', key: 'alpha_AF8' },
      { header: 'alpha_TP10', key: 'alpha_TP10' },
      { header: 'beta_TP9', key: 'beta_TP9' },
      { header: 'beta_AF7', key: 'beta_AF7' },
      { header: 'beta_AF8', key: 'beta_AF8' },
      { header: 'beta_TP10', key: 'beta_TP10' },
      { header: 'gamma_TP9', key: 'gamma_TP9' },
      { header: 'gamma_AF7', key: 'gamma_AF7' },
      { header: 'gamma_AF8', key: 'gamma_AF8' },
      { header: 'gamma_TP10', key: 'gamma_TP10' },
      { key: 'dividerCol' },
      { key: 'dividerCol2' },
      { key: 'dividerCol3' },
      { header: 'Time', key: 'timeAsSeconds' },
      { header: 'Attention', key: 'dTP9' },
      { key: 'dAF7' },
      { key: 'dAF8' },
      { key: 'dTP10' },
      { header: 'Average', key: 'attentionAverage' },
      { header: 'Arousal', key: 'gTP9' },
      { key: 'gAF7' },
      { key: 'gAF8' },
      { key: 'gTP10' },
      { header: 'Average', key: 'arousalAverage' },
      { header: 'Avoid', key: 'avoid' },
      { header: 'Approach', key: 'approach' },
      { header: 'Emotion', key: 'firstStrongest' },
      { key: 'secondStrongest' },
      { key: 'thirdStrongest' }
    ];

    let row;
    let dataRow: AnalyzedMuseDataPerSecond;
    let initialDataRow: number[];

    // let task: Task = this.analyzedData[sheetID-1];
    const task: Task = this.analyzedData[sheetID - 1];

    // for (let i = 0; i < this.analyzedDataArr.length; i++) {
    for (let i = 0; i < task.secondBasedData.length; i++) {
      // this.museValuesPerTaskArr[sheetID-1];
      row = sheet.getRow(i + 2);
      // dataRow = this.analyzedDataArr[i];
      initialDataRow = this.museValuesPerTaskArr[sheetID - 1].taskValuesMuse[i];
      dataRow = task.secondBasedData[i];
      row.values = {
        timeAsSecondsMuse: initialDataRow[0],
        delta_TP9: initialDataRow[1],
        delta_AF7: initialDataRow[2],
        delta_AF8: initialDataRow[3],
        delta_TP10: initialDataRow[4],
        theta_TP9: initialDataRow[5],
        theta_AF7: initialDataRow[6],
        theta_AF8: initialDataRow[7],
        theta_TP10: initialDataRow[8],
        alpha_TP9: initialDataRow[9],
        alpha_AF7: initialDataRow[10],
        alpha_AF8: initialDataRow[11],
        alpha_TP10: initialDataRow[12],
        beta_TP9: initialDataRow[13],
        beta_AF7: initialDataRow[14],
        beta_AF8: initialDataRow[15],
        beta_TP10: initialDataRow[16],
        gamma_TP9: initialDataRow[17],
        gamma_AF7: initialDataRow[18],
        gamma_AF8: initialDataRow[19],
        gamma_TP10: initialDataRow[20],
        timeAsSeconds: dataRow.timeAsSeconds,
        dTP9: dataRow.attentionDeltaTP9,
        dAF7: dataRow.attentionDeltaAF7,
        dAF8: dataRow.attentionDeltaAF8,
        dTP10: dataRow.attentionDeltaTP10,
        attentionAverage: dataRow.attentionAverages,
        gTP9: dataRow.arousalGammaTP9,
        gAF7: dataRow.arousalGammaAF7,
        gAF8: dataRow.arousalGammaAF8,
        gTP10: dataRow.arousalGammaTP10,
        arousalAverage: dataRow.arousalAverages,
        avoid: dataRow.avoid,
        approach: dataRow.approach
      };

      if (this.emotionsDataWasUploaded && this.emotionValuesPerTaskArr.length > 0) {
        for (let i = 0; i < this.emotionValuesPerTaskArr[sheetID - 1].taskValuesEmotion.length; i++) {
          if (this.emotionValuesPerTaskArr[sheetID - 1].taskValuesEmotion[i] != null) {
            const taskTime = dataRow.timeAsSeconds;
            const emotionTime = Number(this.emotionValuesPerTaskArr[sheetID - 1].taskValuesEmotion[i][1]);

            if (taskTime === emotionTime) {
              row.getCell('firstStrongest').value = this.emotionValuesPerTaskArr[sheetID - 1].taskValuesEmotion[i][8];
              row.getCell('secondStrongest').value = this.emotionValuesPerTaskArr[sheetID - 1].taskValuesEmotion[i][9];
              row.getCell('thirdStrongest').value = this.emotionValuesPerTaskArr[sheetID - 1].taskValuesEmotion[i][10];
            }
          }
        }
      }

    }

    const rowID: number = task.secondBasedData.length + 3;
    row = sheet.getRow(rowID);

    row.values = {
      timeAsSecondsMuse: 'Baseline',
      delta_TP9: this.baseLineAverages.deltaTP9,
      delta_AF7: this.baseLineAverages.deltaAF7,
      delta_AF8: this.baseLineAverages.deltaAF8,
      delta_TP10: this.baseLineAverages.deltaTP10,
      theta_TP9: this.baseLineAverages.thetaTP9,
      theta_AF7: this.baseLineAverages.thetaAF7,
      theta_AF8: this.baseLineAverages.thetaAF8,
      theta_TP10: this.baseLineAverages.thetaTP10,
      alpha_TP9: this.baseLineAverages.alphaTP9,
      alpha_AF7: this.baseLineAverages.alphaAF7,
      alpha_AF8: this.baseLineAverages.alphaAF8,
      alpha_TP10: this.baseLineAverages.alphaTP10,
      beta_TP9: this.baseLineAverages.betaTP9,
      beta_AF7: this.baseLineAverages.betaAF7,
      beta_AF8: this.baseLineAverages.betaAF8,
      beta_TP10: this.baseLineAverages.betaTP10,
      gamma_TP9: this.baseLineAverages.gammaTP9,
      gamma_AF7: this.baseLineAverages.gammaAF7,
      gamma_AF8: this.baseLineAverages.gammaAF8,
      gamma_TP10: this.baseLineAverages.gammaTP10,
      attentionAverage: task.averages.averageOfAttentionAverages,
      arousalAverage: task.averages.averageOfArousalAverages,
      avoid: task.averages.avoidAverages,
      approach: task.averages.approachAverages,
    };

    row.eachCell(function (cell) {
      cell.style.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'C4F7C4' },
        bgColor: { argb: 'C4F7C4' },
      };
    });

    const timeCol = sheet.getColumn('timeAsSecondsMuse');
    timeCol.eachCell(function (cell) {
      cell.font = {
        bold: true,
      };
    });

    const headerRow = sheet.getRow(1);
    headerRow.eachCell(function (cell) {
      cell.font = {
        bold: true,
      };
    });

    const timeCol2 = sheet.getColumn('timeAsSeconds');
    timeCol2.eachCell(function (cell) {
      cell.border = {
        right: { style: 'thin' }
      };
      cell.font = {
        bold: true,
      };
    });

    const attentionAverageCol = sheet.getColumn('attentionAverage');
    attentionAverageCol.eachCell(function (cell) {
      cell.border = {
        left: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.font = {
        bold: true,
      };
      if (typeof cell.value === 'number' && cell.value > 0) {
        cell.style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C4F7C4' },
          bgColor: { argb: 'C4F7C4' },
        };
      }
    });

    const arousalAverageCol = sheet.getColumn('arousalAverage');
    arousalAverageCol.eachCell(function (cell) {
      cell.border = {
        left: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.font = {
        bold: true,
      };
      if (typeof cell.value === 'number' && cell.value > 0) {
        cell.style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C4F7C4' },
          bgColor: { argb: 'C4F7C4' },
        };
      }
    });

    const avoidCol = sheet.getColumn('avoid');
    avoidCol.eachCell(function (cell) {
      cell.border = {
        right: { style: 'thin' }
      };
      if (typeof cell.value === 'number' && cell.value > 0) {
        cell.style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F7C4C4' },
          bgColor: { argb: 'F7C4C4' },
        };
      }
    });

    const approachCol = sheet.getColumn('approach');
    approachCol.eachCell(function (cell) {
      if (typeof cell.value === 'number' && cell.value < 0) {
        cell.style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C4F7C4' },
          bgColor: { argb: 'C4F7C4' },
        };
      }
    });

    this.convertToPercentages(sheet.getColumn('dTP9'));
    this.convertToPercentages(sheet.getColumn('dAF7'));
    this.convertToPercentages(sheet.getColumn('dAF8'));
    this.convertToPercentages(sheet.getColumn('dTP10'));
    this.convertToPercentages(sheet.getColumn('attentionAverage'));

    this.convertToPercentages(sheet.getColumn('gTP9'));
    this.convertToPercentages(sheet.getColumn('gAF7'));
    this.convertToPercentages(sheet.getColumn('gAF8'));
    this.convertToPercentages(sheet.getColumn('gTP10'));
    this.convertToPercentages(sheet.getColumn('arousalAverage'));

  }
}

export class MuseValuesPerTask {
  public taskNumber: number;
  public taskValuesMuse: number[][];
}

export class EmotionValuesPerTask {
  public taskNumber: number;
  public taskValuesEmotion: string[][];
}

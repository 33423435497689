import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CSVRecordEyeTrackingEvent, CSVRecordMouseEvent } from '../shared/types/csv-model';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { VideoService } from '../services/video.service';

@Component({
  selector: 'app-steppable-video',
  templateUrl: './steppable-video.component.html',
  styleUrl: './steppable-video.component.scss',
})

export class SteppableVideoComponent {
  @Input({ required: true }) videoUrl: string | ArrayBuffer;
  @Input() width?: number;
  @Input() height?: number;
  /// Things that we show on the canvas overlay based on the video time, and can step through
  @Input() mouseEvents?: CSVRecordMouseEvent[];
  @Input() eyeEvents?: CSVRecordEyeTrackingEvent[];

  @Output() playerReady = new EventEmitter<VgApiService>();

  videoAPI?: VgApiService;

  constructor(private videoService: VideoService) { }

  onPlayerReady(api: VgApiService) {
    this.videoAPI = api;
    this.playerReady.emit(api);
    this.videoService.setApi(api);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.videoAPI) return;
    if (event.code === 'ArrowRight') {
      this.videoAPI.currentTime += 0.1;
      event.preventDefault();
    }
    if (event.code === 'ArrowLeft') {
      this.videoAPI.currentTime -= 0.1;
      event.preventDefault();
    }
    if (event.code === 'ArrowUp') {
      this.videoAPI.volume = Math.min(1, this.videoAPI.volume + 0.1);
      event.preventDefault();
    }
    if (event.code === 'ArrowDown') {
      this.videoAPI.volume = Math.max(0, this.videoAPI.volume - 0.1);
      event.preventDefault();
    }
    if (event.code === 'Space') {
      if (this.videoAPI.state === 'playing') {
        this.videoAPI.pause();
      } else {
        this.videoAPI.play();
      }
      event.preventDefault();
    }
  }

  onLoadedMetadata(event: Event) {
    const video = event.target as HTMLVideoElement;
    this.width = video.videoWidth;
    this.height = video.videoHeight;
    this.videoService.setWidth(this.width);
    this.videoService.setHeight(this.height);
    console.log('Loaded metadata', this.width, this.height);
  }

  seekToTime(time: number) {
    this.videoAPI.currentTime = time;
  }

  startVideoTimer() { }
}

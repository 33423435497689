import { format } from 'date-fns';

export default class Utils {
  isValidCSVFile(file: File) {
    if (!file.name) throw new Error(`${file} is missing a name!`);
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: string[]) {
    const headers = csvRecordsArr[0].split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getHeaderArrayForTSV(csvRecordsArr: string[]) {
    const headers = csvRecordsArr[0].split('\t');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
}

export function createTimestamp(startEpoch: number): string {
  const date = Date.now();
  const timePassed = new Date(date - startEpoch);
  const hours = `${timePassed.getUTCHours()}`.padStart(2, '0');
  const minutes = `${timePassed.getUTCMinutes()}`.padStart(2, '0');
  const seconds = `${timePassed.getUTCSeconds()}`.padStart(2, '0');
  const milliseconds = `${timePassed.getUTCMilliseconds()}`.padStart(3, '0');
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export const formatTimeUTC = (milliseconds: number): string => {
  const localDate = new Date(milliseconds);
  const utcDate = new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
    localDate.getUTCHours(),
    localDate.getUTCMinutes(),
    localDate.getUTCSeconds(),
    localDate.getUTCMilliseconds()
  );
  return format(utcDate, 'H:mm:ss.SS');
};


export function formatTimeWithWords(milliseconds: number): string {
  const units = [
    { name: 'hour', value: 3600000 },
    { name: 'minute', value: 60000 },
    { name: 'second', value: 1000 },
    { name: 'millisecond', value: 1 },
  ];
  let remainingMilliseconds = milliseconds;
  let result = '';
  for (const unit of units) {
    const value = Math.floor(remainingMilliseconds / unit.value);
    if (value > 0) {
      result += `${value} ${unit.name}${value > 1 ? 's' : ''} `;
      remainingMilliseconds -= value * unit.value;
    }
  }
  return result.trim();
}

export function toFixedNumber(num: number, digits: number, base?: number) {
  const pow = Math.pow(base ?? 10, digits);
  return Math.round(num * pow) / pow;
}

export function getTimeOffsetMilliSeconds(timeString: string, date: Date): number {
  // Parse the timeString 'm:ss.sss'
  const [hours, minutes, secondsMilliseconds] = timeString.split(':');
  const [seconds, milliseconds] = secondsMilliseconds.split('.');

  const hoursMillis = parseInt(hours) * 60 * 60 * 1000;
  const minutesMillis = parseInt(minutes) * 60 * 1000;
  const secondsMillis = parseInt(seconds) * 1000;
  const millisecondsMillis = parseInt(milliseconds);

  // Convert to total milliseconds
  const totalMilliseconds = hoursMillis + minutesMillis + secondsMillis + millisecondsMillis;

  const newTime = new Date(date.getTime() + totalMilliseconds);

  const offset = newTime.getTime() - date.getTime();
  return offset / 1000;
}

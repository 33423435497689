import { Component, ViewChild, ElementRef, OnInit, Input, OnDestroy } from '@angular/core';
import { EEGSample } from 'muse-js';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-sensor-visualizer',
  templateUrl: './sensor-visualizer.component.html',
  styleUrls: ['./sensor-visualizer.component.scss']
})

export class SensorVisualizerComponent implements OnInit, OnDestroy {

  @Input() data: Observable<EEGSample>;

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  subscription = new Subscription();
  newData: EEGSample;
  readonly channels = 5;
  private tp9: Square;

  constructor() { }

  ngOnInit() {
    const dataSub = this.data.subscribe(
      (data) => { this.newData = data; }
    );

    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx.font = '24px Arial';
    this.ctx.fillStyle = this.getColors()[0].color;
    this.ctx.fillText('TP9', 72, 320);
    this.ctx.fillStyle = this.getColors()[1].color;
    this.ctx.fillText('AF7', 72, 100);
    this.ctx.fillStyle = this.getColors()[2].color;
    this.ctx.fillText('AF8', 282, 100);
    this.ctx.fillStyle = this.getColors()[3].color;
    this.ctx.fillText('TP10', 282, 320);

    const dataSub2 = this.data
      .subscribe((sample) => {
        sample.data.slice(0, this.channels).map((value, electrode) => {
          // TP9
          if (electrode === 0) {
            this.indicateSignalQuality(value, 0, 120, 300);
          }

          // AF7
          if (electrode === 1) {
            this.indicateSignalQuality(value, 1, 120, 80);
          }

          // AF8
          if (electrode === 2) {
            this.indicateSignalQuality(value, 2, 260, 80);
          }

          // TP10
          if (electrode === 3) {
            this.indicateSignalQuality(value, 3, 260, 300);
          }
        });
      });

    this.subscription.add(dataSub);
    this.subscription.add(dataSub2);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  getColors() {
    return [
      { color: 'rgba(112,185,252,1)', inactiveColor: 'rgba(237,33,33,1)' },
      { color: 'rgba(116,150,161,1)', inactiveColor: 'rgba(237,33,33,1)' },
      { color: 'rgba(162,86,178,1)', inactiveColor: 'rgba(237,33,33,1)' },
      { color: 'rgba(144,132,246,1)', inactiveColor: 'rgba(237,33,33,1)' }
    ];
  }

  indicateSignalQuality(value, electrode: number, x: number, y: number) {
    if (value > 250) {
      // this.tp9.clear(80, 300, 20);
      this.ctx.fillStyle = this.getColors()[electrode].inactiveColor;
      this.tp9 = new Square(this.ctx);
      this.tp9.draw(x, y, 20);
    } else if (value < -300) {
      // this.tp9.clear(80, 300, 20);
      this.ctx.fillStyle = this.getColors()[electrode].inactiveColor;
      this.tp9 = new Square(this.ctx);
      this.tp9.draw(x, y, 20);
    } else {
      this.ctx.fillStyle = this.getColors()[electrode].color;
      this.tp9 = new Square(this.ctx);
      this.tp9.draw(x, y, 20);
    }
  }
}

export class Square {
  constructor(private ctx: CanvasRenderingContext2D) { }

  draw(x: number, y: number, z: number) {
    this.ctx.fillRect(x, y, z, z);
  }

  clear(x: number, y: number, z: number) {
    this.ctx.clearRect(x, y, z, z);
  }
}


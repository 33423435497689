import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import Utils from '../utils';
import { CSVRecordMuse, MuseDataPerSecond, MuseAveragesPerSecond } from '../shared/types/csv-model';
import { TimeStampModel } from '../shared/types/timestamp-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../services/communication.service';
import { FileDataType } from '../file-input/recorded-data-enums';
import { CachedFile } from '../file-input/request-files';

@Component({
  selector: 'app-process-muse-file',
  templateUrl: './process-muse-file.component.html',
  styleUrls: ['./process-muse-file.component.scss']
})
export class ProcessMuseFileComponent implements OnInit {
  public museRecords: CSVRecordMuse[] = [];
  public museUpdatedRecords: CSVRecordMuse[] = [];
  public timestampArr: string[] = [];
  public timestampAsModelArr: TimeStampModel[] = [];
  public startingTSAsModel: TimeStampModel;
  public timestampStringSTArr: string[] = [];
  public csvRecordsArray: string[] = [];

  public isMoraeFile = false;
  public isMuseFile = false;
  public isEmotionFile = false;
  public isInvalidFile = false;
  public timestampExists = false;
  timeStampsAsSeconds: number;
  timeStampsAsSecondsArr: number[];
  strongestEmotion: string;
  secondStrongestEmotion: string;
  thirdStrongestEmotion: string;
  analyzedMuseData: number[][];

  file: File;

  private subscription = new Subscription();

  @Output() sendMuseDataEvent = new EventEmitter<ProcessMuseFileComponent["analyzedMuseData"]>();

  constructor(private snackbar: MatSnackBar, private comData: CommunicationService) { }

  ngOnInit() {
    this.subscription.add(this.comData.getCachedFile(FileDataType.MuseDataSynced).subscribe((file) => {
      if (file) {
        this.onFileUpload(file);
      }
    }));
  }

  private async onFileUpload(cachedFile: CachedFile) {
    const u = new Utils();

    if (!u.isValidCSVFile(cachedFile.file)) {
      this.snackbar.open('Uploaded file is not a valid .csv file.', 'Dismiss');
      return;
    }
    this.file = cachedFile.file;


    const csvData = await cachedFile.file.text()
    if (typeof csvData !== 'string') {
      return;
    }
    this.csvRecordsArray = csvData.split(/\r\n|\n/);
    const headersRow = u.getHeaderArray(this.csvRecordsArray);

    if (headersRow[0].substring(0, 12) === 'Elapsed Time') {
      this.isMoraeFile = true;
    } else if (headersRow[1] === 'angry') {
      this.isEmotionFile = true;
    } else if (headersRow[1] === 'delta_TP9') {
      this.isMuseFile = true;
    } else {
      this.isInvalidFile = true;
    }

    if (this.isMuseFile) {
      this.analyzedMuseData = [];
      // TODO: clean data

      // read CSV
      this.museRecords = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
      // remove NaN rows
      this.museUpdatedRecords = this.removeZeroRows(this.museRecords);
      // parse timestamps
      this.parseTimestampsAsModels(this.timestampArr);

      // timestamps as seconds
      this.timeStampsAsSecondsArr = this.convertTimestampsToSeconds();
      this.analyzedMuseData = this.createAnalyzedMuseCSV(this.museUpdatedRecords);
      this.sendMuseDataEvent.emit(this.analyzedMuseData);

      // this.saveToCSV(this.analyzedData);
    } else {
      this.snackbar.open('Uploaded file is invalid.', 'Dismiss');
    }

  }

  removeZeroRows(records: CSVRecordMuse[]) {
    const cleanedDataArray: ProcessMuseFileComponent["museUpdatedRecords"] = [];
    for (let i = 0; i < records.length; i++) {
      if (records[i].deltaTP9Column !== '0' && records[i].deltaAF7Column !== '0' && records[i].deltaAF8Column !== '0' && records[i].deltaTP10Column !== '0' &&
        records[i].thetaTP9Column !== '0' && records[i].thetaAF7Column !== '0' && records[i].thetaAF8Column !== '0' && records[i].thetaTP10Column !== '0' &&
        records[i].alphaTP9Column !== '0' && records[i].alphaAF7Column !== '0' && records[i].alphaAF8Column !== '0' && records[i].alphaTP10Column !== '0' &&
        records[i].betaTP9Column !== '0' && records[i].betaAF7Column !== '0' && records[i].betaAF8Column !== '0' && records[i].betaTP10Column !== '0' &&
        records[i].gammaTP9Column !== '0' && records[i].gammaAF7Column !== '0' && records[i].gammaAF8Column !== '0' && records[i].gammaTP10Column !== '0') {
        cleanedDataArray.push(records[i]);
        this.timestampArr.push(records[i].timeColumn);
      }
    }
    return cleanedDataArray;
  }

  createAnalyzedMuseCSV(cleanedDataArray: CSVRecordMuse[]) {
    for (let i = 0; i < cleanedDataArray.length; i++) {
      cleanedDataArray[i].timeAsSeconds = this.timeStampsAsSecondsArr[i];
    }
    const analyzedDataArray: number[][] = [];
    let dataPerSecond = new MuseDataPerSecond();
    let averagesPerSecond = new MuseAveragesPerSecond();
    let currentTimeAsSeconds = cleanedDataArray.length ? cleanedDataArray[0].timeAsSeconds : 0;

    for (let i = 0; i < cleanedDataArray.length; i++) {
      if (cleanedDataArray[i].timeAsSeconds === currentTimeAsSeconds) {
        dataPerSecond.timeAsSeconds = currentTimeAsSeconds;
        dataPerSecond.deltaTP9.push(cleanedDataArray[i].deltaTP9Column);
        dataPerSecond.deltaAF7.push(cleanedDataArray[i].deltaAF7Column);
        dataPerSecond.deltaAF8.push(cleanedDataArray[i].deltaAF8Column);
        dataPerSecond.deltaTP10.push(cleanedDataArray[i].deltaTP10Column);

        dataPerSecond.thetaTP9.push(cleanedDataArray[i].thetaTP9Column);
        dataPerSecond.thetaAF7.push(cleanedDataArray[i].thetaAF7Column);
        dataPerSecond.thetaAF8.push(cleanedDataArray[i].thetaAF8Column);
        dataPerSecond.thetaTP10.push(cleanedDataArray[i].thetaTP10Column);

        dataPerSecond.alphaTP9.push(cleanedDataArray[i].alphaTP9Column);
        dataPerSecond.alphaAF7.push(cleanedDataArray[i].alphaAF7Column);
        dataPerSecond.alphaAF8.push(cleanedDataArray[i].alphaAF8Column);
        dataPerSecond.alphaTP10.push(cleanedDataArray[i].alphaTP10Column);

        dataPerSecond.betaTP9.push(cleanedDataArray[i].betaTP9Column);
        dataPerSecond.betaAF7.push(cleanedDataArray[i].betaAF7Column);
        dataPerSecond.betaAF8.push(cleanedDataArray[i].betaAF8Column);
        dataPerSecond.betaTP10.push(cleanedDataArray[i].betaTP10Column);

        dataPerSecond.gammaTP9.push(cleanedDataArray[i].gammaTP9Column);
        dataPerSecond.gammaAF7.push(cleanedDataArray[i].gammaAF7Column);
        dataPerSecond.gammaAF8.push(cleanedDataArray[i].gammaAF8Column);
        dataPerSecond.gammaTP10.push(cleanedDataArray[i].gammaTP10Column);

        /*if ((i-1) === cleanedDataArray.length) {
          console.log('END!');
          averagesPerSecond = this.getAverages(dataPerSecond);
          analyzedDataArray.push(averagesPerSecond);
          analyzedDataArrayFinal.push([averagesPerSecond.timeAsSeconds, averagesPerSecond.deltaTP9, averagesPerSecond.deltaAF7, averagesPerSecond.deltaAF8, averagesPerSecond.deltaTP10,
            averagesPerSecond.thetaTP9, averagesPerSecond.thetaAF7, averagesPerSecond.thetaAF8, averagesPerSecond.thetaTP10,
            averagesPerSecond.alphaTP9, averagesPerSecond.alphaAF7, averagesPerSecond.alphaAF8, averagesPerSecond.alphaTP10,
            averagesPerSecond.betaTP9, averagesPerSecond.betaAF7, averagesPerSecond.betaAF8, averagesPerSecond.betaTP10,
            averagesPerSecond.gammaTP9, averagesPerSecond.gammaAF7, averagesPerSecond.gammaAF8, averagesPerSecond.gammaTP10]);
          dataPerSecond = new MuseDataPerSecond();
          averagesPerSecond = new MuseAveragesPerSecond();
        }*/

      } else {
        averagesPerSecond = this.getAverages(dataPerSecond);
        analyzedDataArray.push([averagesPerSecond.timeAsSeconds, averagesPerSecond.deltaTP9, averagesPerSecond.deltaAF7, averagesPerSecond.deltaAF8, averagesPerSecond.deltaTP10,
        averagesPerSecond.thetaTP9, averagesPerSecond.thetaAF7, averagesPerSecond.thetaAF8, averagesPerSecond.thetaTP10,
        averagesPerSecond.alphaTP9, averagesPerSecond.alphaAF7, averagesPerSecond.alphaAF8, averagesPerSecond.alphaTP10,
        averagesPerSecond.betaTP9, averagesPerSecond.betaAF7, averagesPerSecond.betaAF8, averagesPerSecond.betaTP10,
        averagesPerSecond.gammaTP9, averagesPerSecond.gammaAF7, averagesPerSecond.gammaAF8, averagesPerSecond.gammaTP10]);
        dataPerSecond = new MuseDataPerSecond();
        averagesPerSecond = new MuseAveragesPerSecond();

        currentTimeAsSeconds = cleanedDataArray[i].timeAsSeconds;

        dataPerSecond.timeAsSeconds = currentTimeAsSeconds;
        dataPerSecond.deltaTP9.push(cleanedDataArray[i].deltaTP9Column);
        dataPerSecond.deltaAF7.push(cleanedDataArray[i].deltaAF7Column);
        dataPerSecond.deltaAF8.push(cleanedDataArray[i].deltaAF8Column);
        dataPerSecond.deltaTP10.push(cleanedDataArray[i].deltaTP10Column);

        dataPerSecond.thetaTP9.push(cleanedDataArray[i].thetaTP9Column);
        dataPerSecond.thetaAF7.push(cleanedDataArray[i].thetaAF7Column);
        dataPerSecond.thetaAF8.push(cleanedDataArray[i].thetaAF8Column);
        dataPerSecond.thetaTP10.push(cleanedDataArray[i].thetaTP10Column);

        dataPerSecond.alphaTP9.push(cleanedDataArray[i].alphaTP9Column);
        dataPerSecond.alphaAF7.push(cleanedDataArray[i].alphaAF7Column);
        dataPerSecond.alphaAF8.push(cleanedDataArray[i].alphaAF8Column);
        dataPerSecond.alphaTP10.push(cleanedDataArray[i].alphaTP10Column);

        dataPerSecond.betaTP9.push(cleanedDataArray[i].betaTP9Column);
        dataPerSecond.betaAF7.push(cleanedDataArray[i].betaAF7Column);
        dataPerSecond.betaAF8.push(cleanedDataArray[i].betaAF8Column);
        dataPerSecond.betaTP10.push(cleanedDataArray[i].betaTP10Column);

        dataPerSecond.gammaTP9.push(cleanedDataArray[i].gammaTP9Column);
        dataPerSecond.gammaAF7.push(cleanedDataArray[i].gammaAF7Column);
        dataPerSecond.gammaAF8.push(cleanedDataArray[i].gammaAF8Column);
        dataPerSecond.gammaTP10.push(cleanedDataArray[i].gammaTP10Column);

        // dataPerSecond = new MuseDataPerSecond();
        // averagesPerSecond = new MuseAveragesPerSecond();


      }
    }

    console.log('END');
    averagesPerSecond = this.getAverages(dataPerSecond);
    analyzedDataArray.push([averagesPerSecond.timeAsSeconds, averagesPerSecond.deltaTP9, averagesPerSecond.deltaAF7, averagesPerSecond.deltaAF8, averagesPerSecond.deltaTP10,
    averagesPerSecond.thetaTP9, averagesPerSecond.thetaAF7, averagesPerSecond.thetaAF8, averagesPerSecond.thetaTP10,
    averagesPerSecond.alphaTP9, averagesPerSecond.alphaAF7, averagesPerSecond.alphaAF8, averagesPerSecond.alphaTP10,
    averagesPerSecond.betaTP9, averagesPerSecond.betaAF7, averagesPerSecond.betaAF8, averagesPerSecond.betaTP10,
    averagesPerSecond.gammaTP9, averagesPerSecond.gammaAF7, averagesPerSecond.gammaAF8, averagesPerSecond.gammaTP10]);
    dataPerSecond = new MuseDataPerSecond();
    averagesPerSecond = new MuseAveragesPerSecond();

    // calculate total averages
    /*console.log('analyzedDataArray.length: ' + analyzedDataArray.length);

    let deltaTP9List = [];
    let deltaAF7List = [];
    let deltaAF8List = [];
    let deltaTP10List = [];
    let thetaTP9List = [];
    let thetaAF7List = [];
    let thetaAF8List = [];
    let thetaTP10List = [];
    let alphaTP9List = [];
    let alphaAF7List = [];
    let alphaAF8List = [];
    let alphaTP10List = [];
    let betaTP9List = [];
    let betaAF7List = [];
    let betaAF8List = [];
    let betaTP10List = [];
    let gammaTP9List = [];
    let gammaAF7List = [];
    let gammaAF8List = [];
    let gammaTP10List = [];

    let totalAveragesList = [];

    for (let i = 0; i < analyzedDataArray.length; i++) {
      deltaTP9List.push(analyzedDataArray[i].deltaTP9);
      deltaAF7List.push(analyzedDataArray[i].deltaAF7);
      deltaAF8List.push(analyzedDataArray[i].deltaAF8);
      deltaTP10List.push(analyzedDataArray[i].deltaTP10);
      thetaTP9List.push(analyzedDataArray[i].thetaTP9);
      thetaAF7List.push(analyzedDataArray[i].thetaAF7);
      thetaAF8List.push(analyzedDataArray[i].thetaAF8);
      thetaTP10List.push(analyzedDataArray[i].thetaTP10);
      alphaTP9List.push(analyzedDataArray[i].alphaTP9);
      alphaAF7List.push(analyzedDataArray[i].alphaAF7);
      alphaAF8List.push(analyzedDataArray[i].alphaAF8);
      alphaTP10List.push(analyzedDataArray[i].alphaTP10);
      betaTP9List.push(analyzedDataArray[i].betaTP9);
      betaAF7List.push(analyzedDataArray[i].betaAF7);
      betaAF8List.push(analyzedDataArray[i].betaAF8);
      betaTP10List.push(analyzedDataArray[i].betaTP10);
      gammaTP9List.push(analyzedDataArray[i].gammaTP9);
      gammaAF7List.push(analyzedDataArray[i].gammaAF7);
      gammaAF8List.push(analyzedDataArray[i].gammaAF8);
      gammaTP10List.push(analyzedDataArray[i].gammaTP10);
    }

    totalAveragesList.push('Total averages');
    totalAveragesList.push(this.calculateAverage(deltaTP9List));
    totalAveragesList.push(this.calculateAverage(deltaAF7List));
    totalAveragesList.push(this.calculateAverage(deltaAF8List));
    totalAveragesList.push(this.calculateAverage(deltaTP10List));

    totalAveragesList.push(this.calculateAverage(thetaTP9List));
    totalAveragesList.push(this.calculateAverage(thetaAF7List));
    totalAveragesList.push(this.calculateAverage(thetaAF8List));
    totalAveragesList.push(this.calculateAverage(thetaTP10List));

    totalAveragesList.push(this.calculateAverage(alphaTP9List));
    totalAveragesList.push(this.calculateAverage(alphaAF7List));
    totalAveragesList.push(this.calculateAverage(alphaAF8List));
    totalAveragesList.push(this.calculateAverage(alphaTP10List));

    totalAveragesList.push(this.calculateAverage(betaTP9List));
    totalAveragesList.push(this.calculateAverage(betaAF7List));
    totalAveragesList.push(this.calculateAverage(betaAF8List));
    totalAveragesList.push(this.calculateAverage(betaTP10List));

    totalAveragesList.push(this.calculateAverage(gammaTP9List));
    totalAveragesList.push(this.calculateAverage(gammaAF7List));
    totalAveragesList.push(this.calculateAverage(gammaAF8List));
    totalAveragesList.push(this.calculateAverage(gammaTP10List));

    console.log('list length: ' + deltaTP9List.length);
    console.log(totalAveragesList);

    return analyzedDataArrayFinal;*/

    return analyzedDataArray;
  }

  calculateAverage(list: string[]) {
    let sum = 0;
    // console.log(list);
    for (let i = 0; i < list.length; i++) {
      sum += parseFloat(list[i]);
    }
    // console.log('sum: ' + sum);
    // console.log('list length: ' + list.length);
    const average = sum / list.length;
    // console.log('average: ' + average);
    return average;
  }

  public getAverages(dataPerSecond: MuseDataPerSecond) {
    const averagesPerSecond = new MuseAveragesPerSecond();

    averagesPerSecond.timeAsSeconds = dataPerSecond.timeAsSeconds;
    averagesPerSecond.deltaTP9 = this.calculateAverage(dataPerSecond.deltaTP9);
    averagesPerSecond.deltaAF7 = this.calculateAverage(dataPerSecond.deltaAF7);
    averagesPerSecond.deltaAF8 = this.calculateAverage(dataPerSecond.deltaAF8);
    averagesPerSecond.deltaTP10 = this.calculateAverage(dataPerSecond.deltaTP10);

    averagesPerSecond.thetaTP9 = this.calculateAverage(dataPerSecond.thetaTP9);
    averagesPerSecond.thetaAF7 = this.calculateAverage(dataPerSecond.thetaAF7);
    averagesPerSecond.thetaAF8 = this.calculateAverage(dataPerSecond.thetaAF8);
    averagesPerSecond.thetaTP10 = this.calculateAverage(dataPerSecond.thetaTP10);

    averagesPerSecond.alphaTP9 = this.calculateAverage(dataPerSecond.alphaTP9);
    averagesPerSecond.alphaAF7 = this.calculateAverage(dataPerSecond.alphaAF7);
    averagesPerSecond.alphaAF8 = this.calculateAverage(dataPerSecond.alphaAF8);
    averagesPerSecond.alphaTP10 = this.calculateAverage(dataPerSecond.alphaTP10);

    averagesPerSecond.betaTP9 = this.calculateAverage(dataPerSecond.betaTP9);
    averagesPerSecond.betaAF7 = this.calculateAverage(dataPerSecond.betaAF7);
    averagesPerSecond.betaAF8 = this.calculateAverage(dataPerSecond.betaAF8);
    averagesPerSecond.betaTP10 = this.calculateAverage(dataPerSecond.betaTP10);

    averagesPerSecond.gammaTP9 = this.calculateAverage(dataPerSecond.gammaTP9);
    averagesPerSecond.gammaAF7 = this.calculateAverage(dataPerSecond.gammaAF7);
    averagesPerSecond.gammaAF8 = this.calculateAverage(dataPerSecond.gammaAF8);
    averagesPerSecond.gammaTP10 = this.calculateAverage(dataPerSecond.gammaTP10);
    return averagesPerSecond;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: ProcessMuseFileComponent["csvRecordsArray"], headerLength: number) {
    // Muse
    const csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      const currentRecord = (csvRecordsArray[i] as string).split(',');
      if (currentRecord.length === headerLength) {
        const csvRecord: CSVRecordMuse = new CSVRecordMuse();
        csvRecord.timeColumn = currentRecord[0].trim();
        csvRecord.deltaTP9Column = currentRecord[1].trim();
        csvRecord.deltaAF7Column = currentRecord[2].trim();
        csvRecord.deltaAF8Column = currentRecord[3].trim();
        csvRecord.deltaTP10Column = currentRecord[4].trim();

        csvRecord.thetaTP9Column = currentRecord[5].trim();
        csvRecord.thetaAF7Column = currentRecord[6].trim();
        csvRecord.thetaAF8Column = currentRecord[7].trim();
        csvRecord.thetaTP10Column = currentRecord[8].trim();

        csvRecord.alphaTP9Column = currentRecord[9].trim();
        csvRecord.alphaAF7Column = currentRecord[10].trim();
        csvRecord.alphaAF8Column = currentRecord[11].trim();
        csvRecord.alphaTP10Column = currentRecord[12].trim();

        csvRecord.betaTP9Column = currentRecord[13].trim();
        csvRecord.betaAF7Column = currentRecord[14].trim();
        csvRecord.betaAF8Column = currentRecord[15].trim();
        csvRecord.betaTP10Column = currentRecord[16].trim();

        csvRecord.gammaTP9Column = currentRecord[17].trim();
        csvRecord.gammaAF7Column = currentRecord[18].trim();
        csvRecord.gammaAF8Column = currentRecord[19].trim();
        csvRecord.gammaTP10Column = currentRecord[20].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  parseTimestampsAsModels(timestampArr: ProcessMuseFileComponent["timestampArr"]) {
    for (let i = 0; i < timestampArr.length; i++) {
      const timestampAsModel = new TimeStampModel();
      const timestampNS = timestampArr[i].split(':');
      timestampAsModel.hours = parseFloat(timestampNS[0]);
      timestampAsModel.minutes = parseFloat(timestampNS[1]);
      // Round up milliseconds up to standardize with Morae timetamps
      // const roundedTimestampSeconds = timestampNS[3].toFixed(2);
      // const timestampSecondsSplitNS = roundedTimestampSeconds[2].split('.');
      const timestampSecondsSplitNS = timestampNS[2].split('.');
      timestampAsModel.seconds = parseFloat(timestampSecondsSplitNS[0]);
      timestampAsModel.millis = parseFloat(timestampSecondsSplitNS[1]);
      this.timestampAsModelArr.push(timestampAsModel);
    }
  }

  convertTimestampsToSeconds() {
    const timestampsAsSecondsArr = [];

    // convert timestamps in array into seconds
    this.timestampAsModelArr.forEach(element => {
      this.timeStampsAsSeconds = parseInt(element.seconds.toString()) + parseInt(element.minutes.toString()) * 60 + parseInt(element.hours.toString()) * 3600;
      timestampsAsSecondsArr.push(this.timeStampsAsSeconds);
    });
    return timestampsAsSecondsArr;
  }

  // saveToCSV(updatedRecords: any) {
  //   const a = document.createElement('a');
  //   let headers = [];
  //   if (this.isMuseFile) {
  //     headers = ['time in seconds', 'delta_TP9', 'delta_AF7', 'delta_AF8', 'delta_TP10', 'theta_TP9', 'theta_AF7', 'theta_AF8', 'theta_TP10', 'alpha_TP9', 'alpha_AF7', 'alpha_AF8', 'alpha_TP10', 'beta_TP9', 'beta_AF7', 'beta_AF8', 'beta_TP10', 'gamma_TP9', 'gamma_AF7', 'gamma_AF8', 'gamma_TP10'];
  //     a.download = 'muse_averages.csv';
  //     this.isMuseFile = false;
  //   }
  //   // const headers = ['time', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral'];
  //   const csvData = headers + '\n' + updatedRecords.map(item => item.join(',')).join('\n');
  //   const file = new Blob([csvData], { type: 'text/csv' });
  //   a.href = URL.createObjectURL(file);
  //   document.body.appendChild(a);
  //   // a.download = 'emotions_standardized.csv';
  //   a.click();
  //   document.body.removeChild(a);
  //   this.museUpdatedRecords = [];
  //   this.timestampArr = [];
  //   this.timestampAsModelArr = [];
  //   this.timestampStringSTArr = [];
  // }
}

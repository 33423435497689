import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { CommunicationService } from './services/communication.service';


interface RouteLinks {
  label: string;
  link: string;
  index: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  routeLinks: RouteLinks[];
  activeLinkIndex = -1;

  constructor(private router: Router, private comData: CommunicationService) {
    this.routeLinks = [
      {
        label: 'Recording',
        link: './recording',
        index: 0
      }, {
        label: 'Converting',
        link: './converting',
        index: 1
      }, {
        label: 'Data processing',
        link: './clean-data',
        index: 2
      },
      {
        label: 'Present results',
        link: './present-data',
        index: 3
      }
    ];
  }
  ngOnInit() { }

}

<section id="converter-container">
  <h1>Timestamp syncronizer converter</h1>
  <p>
    Upload your unsynced data files here to syncronize them, to allow them to be usable in Present Results <br>
    This will convert their unix timestamps to syncronized hours:minutesseconds.milliseconds, ie 0:00:12.232,<br>
    allowing them to be used in the Present Results section
  </p>
  <app-file-input style="flex-direction: column;" [requestFileFlags]="requestInputs" [uploadText]="uploadMessage" />

  <p id="timestampText"></p>
</section>

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})

export class FooterComponent {

  constructor(private router: Router) { }

  getConsoleAppURL() {
    return environment.consoleAppURL || "#";
  }

  getFFmpegSourceURL() {
    return environment.FFmpegURL || "#"
  }

}

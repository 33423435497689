import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { epoch, fft, powerByBand } from '@neurosity/pipes';
import { EEGSample } from 'muse-js';
import { Observable, Subscription } from 'rxjs';
import { map, timestamp } from 'rxjs/operators';
import { CommunicationService } from '../services/communication.service';
import { createTimestamp } from '../utils';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'eeg-recorder',
  templateUrl: './eeg-recorder.component.html',
  styleUrls: ['./eeg-recorder.component.scss'],
})
export class EEGRecorder implements OnInit, OnDestroy {
  @Input() data: Observable<EEGSample>;

  recording = false;
  private samples: (string | number)[][] = [];
  private subscription = new Subscription();
  private timer: NodeJS.Timeout;

  private count = 0;

  readyToRecord: boolean;

  private startEpoch = 0;

  constructor(private comData: CommunicationService, private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    const canStartRecordingSub = this.comData.canStartRecording.subscribe(recordingMessage => this.readyToRecord = recordingMessage);

    const isRecordingSub = this.comData.isRecording.subscribe(isRecording => {
      if (isRecording) this.tryStartRecording();
      else if (this.recording) this.stopRecording();
    });

    this.subscription.add(canStartRecordingSub);
    this.subscription.add(isRecordingSub);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this.recording = false;
    this.newRecordingMessage();
  }

  tryStartRecording() {
    this.startEpoch = Date.now();
    const timeStampString = createTimestamp(this.startEpoch);
    if (!this.recording) {
      this.samples = [];
      this.samples.push([
        timeStampString, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
      this.startRecording();
    }

    if (environment.production) {
      this.snackbar.open('Camera is not ready yet or camera permissions are missing', "Dismiss");
    }
  }

  newRecordingMessage() {
    this.comData.changeMuseReady(this.recording);
  }

  startRecording() {
    this.recording = true;
    this.timer = setInterval(() => this.count++, 1000);
    this.newRecordingMessage();

    if (this.data) {
      this.subscription = this.data
        .pipe(
          map((rawData) => {
            const c = { ...rawData };
            c.data = c.data.splice(0, 4);
            return c;
          }),

          epoch({ interval: 10 }),
          fft({ bins: 256 }),
          powerByBand(),
          timestamp(),
          map((info) => {
            const infoObj = info;
            // TODO check typing here
            // console.log(infoObj);
            const c = { ...infoObj, timestampString: '' };
            // var date = new Date(c.timestamp);
            c.timestampString = createTimestamp(this.startEpoch);

            return c;
          })
        )
        .subscribe((buffer) => {
          this.samples.push([
            buffer.timestampString,
            ...buffer.value.delta,
            ...buffer.value.theta,
            ...buffer.value.alpha,
            ...buffer.value.beta,
            ...buffer.value.gamma,
          ]);
        });
    }
  }

  get timeStamp() {
    return `${this.count} seconds`;
  }

  get samplesCount() {
    return `total muse samples counted: ${this.samples.length}`;
  }

  stopRecording() {
    if (!this.recording) return;
    this.recording = false;
    // this.newRecordingMessage();
    this.count = 0;
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.saveToCsv(this.samples);
  }

  saveToCsv(samples: EEGRecorder['samples']) {
    const a = document.createElement('a');
    // header has no 'beta_TP10' and ha 'gamma_TP10' twice ?
    // const headers = [
    //   'time',
    //   'delta_TP9',
    //   'delta_AF7',
    //   'delta_AF8',
    //   'delta_TP10',
    //   'theta_TP9',
    //   'theta_AF7',
    //   'theta_AF8',
    //   'theta_TP10',
    //   'alpha_TP9',
    //   'alpha_AF7',
    //   'alpha_AF8',
    //   'alpha_TP10',
    //   'beta_TP9',
    //   'beta_AF7',
    //   'beta_AF8',
    //   'gamma_TP10',
    //   'gamma_TP9',
    //   'gamma_AF7',
    //   'gamma_AF8',
    //   'gamma_TP10'
    // ];
    // changed first 'gamma_TP10' into 'beta_TP10'
    const headers = [
      'time',
      'delta_TP9',
      'delta_AF7',
      'delta_AF8',
      'delta_TP10',
      'theta_TP9',
      'theta_AF7',
      'theta_AF8',
      'theta_TP10',
      'alpha_TP9',
      'alpha_AF7',
      'alpha_AF8',
      'alpha_TP10',
      'beta_TP9',
      'beta_AF7',
      'beta_AF8',
      'beta_TP10',
      'gamma_TP9',
      'gamma_AF7',
      'gamma_AF8',
      'gamma_TP10',
    ];
    const csvData =
      headers + '\n' + samples.map((item) => item.join(',')).join('\n');
    const file = new Blob([csvData], { type: 'text/csv' });
    a.href = URL.createObjectURL(file);
    document.body.appendChild(a);
    a.download = 'muse_raw.csv';
    a.click();
    document.body.removeChild(a);
  }
}

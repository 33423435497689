import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CSVRecordEmotions,
  CSVRecordEyeTrackingEvent,
  CSVRecordKeyboardEvent,
  CSVRecordMouseEvent,
  CSVRecordMuse
} from '../shared/types/csv-model';
import { TimeStampModel } from '../shared/types/timestamp-model';
import Utils from '../utils';
import { format } from 'date-fns';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenRecorderComponent } from '../data-recorder/data-recorder.component';
import { keyboardEventsSyncedFile, eyeTrackingFileSynced, museFile, emotionFileSynced, mouseEventsSynced, projectDataFile, museFileSynced } from '../file-input/request-files-presets';
import { FileDataType } from '../file-input/recorded-data-enums';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../services/communication.service';
import { allFileTemplates, CachedFile, getDefaultFileName, getDefaultFlagName } from '../file-input/request-files';
import { FileInputComponent } from '../file-input/file-input.component';

// Abandon hope all ye who enter here
@Component({
  selector: 'app-timestamp-converter',
  templateUrl: './timestamp-converter.component.html',
  styleUrls: ['./timestamp-converter.component.scss']
})
export class TimestampConverterComponent implements OnInit, OnDestroy {
  public records: (CSVRecordMuse | CSVRecordEmotions | CSVRecordMouseEvent | CSVRecordEyeTrackingEvent)[] = [];
  public updatedRecords = [];
  public timestampArr: string[] = [];
  public timestampAsModelArr: TimeStampModel[] = [];
  public startingTimestamp: string;
  public startingTSAsModel: TimeStampModel;
  public timestampStringSTArr: string[] = [];
  public csvRecordsArray: string[] = [];
  public requestInputs = FileDataType.ProjectData;

  public isMoraeFile = false;
  public isMuseFile = false;
  public isEyeTrackingFile = false;
  public isMouseEventFile = false;
  public isKeyboardEventFile = false;
  public isEmotionFile = false;

  public allFileTemplates = allFileTemplates;


  public _timestampExists = false;
  get timestampExists() {
    return this._timestampExists;
  }
  set timestampExists(value) {
    this._timestampExists = value;
    if (value) {
      this.onTimeStampFound();
    }
  }

  public uploadMessage: FileInputComponent["uploadText"] = {
    [FileDataType.ProjectData]: `Upload the ${projectDataFile.displayName} ${getDefaultFileName(projectDataFile)} file from the emotion data recording`,
    [FileDataType.MuseDataSynced]: `Upload the ${museFile.displayName} ${getDefaultFileName(museFile)} file from the Muse data recording`
  };

  file: File;

  private subscription = new Subscription();

  constructor(private snackbar: MatSnackBar, private comData: CommunicationService) { }

  ngOnInit() {
    this.subscription.add(this.comData.getCachedFile(FileDataType.ProjectData).subscribe((CachedFile) => {
      this.onUpload(CachedFile);
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  private onTimeStampFound() {
    const uploadableFiles = [FileDataType.MuseData, FileDataType.EyeTracking, FileDataType.MouseEvents, FileDataType.KeyboardEvents, FileDataType.EmotionData];
    uploadableFiles.forEach((fileType) => {
      // add the enum to the request inputs
      this.requestInputs = this.requestInputs | fileType;
      this.uploadMessage[fileType] = `Upload ${getDefaultFileName(allFileTemplates.find(file => file.flag === fileType))}.`;
      this.subscription.add(this.comData.getCachedFile(fileType).subscribe((CachedFile) => {
        this.onUpload(CachedFile);
      }));
    });
  }

  private async onUpload(cachedFile: CachedFile) {
    const u = new Utils();
    if (!cachedFile) return;
    if (u.isValidCSVFile(cachedFile.file)) {
      this.file = cachedFile.file;

      const fileString = cachedFile.parsedData ?? await cachedFile.file.text();
      if (typeof fileString !== 'string') throw new Error("Failed to read CSV file");
      this.csvRecordsArray = fileString.split(/\r\n|\n/); // match carriage return (\r\n) + linefeed (\n) or just line feed
      if (this.csvRecordsArray.length == 1) throw new Error("Failed to parse CSV file");
      const headersRow = u.getHeaderArray(this.csvRecordsArray); // each entry is a csv row, the first being the header

      // parse first row - if second cell's content is 'Recording' - Morae Data, else if it is 'angry' - Emotion Data, else if it is 'delta_TP9' - Muse data, else - invalid data
      // TODO MOVE everything to functions on the file type definitions

      if (headersRow[0].substring(0, 12) === 'Elapsed Time' && cachedFile.flag === FileDataType.ProjectData) {
        this.isMoraeFile = true;
      } else if (headersRow[1] === 'angry' && cachedFile.flag === FileDataType.EmotionData) {
        this.isEmotionFile = true;
      } else if (headersRow[1] === 'delta_TP9' && cachedFile.flag === FileDataType.MuseData) {
        this.isMuseFile = true;
      } else if (headersRow[1] === 'ButtonName' && headersRow[3] == "X" && headersRow[4] === "Y" && cachedFile.flag === FileDataType.MouseEvents) {
        this.isMouseEventFile = true;
      } else if (headersRow[1] === 'ButtonName' && cachedFile.flag === FileDataType.KeyboardEvents) {
        this.isKeyboardEventFile = true;
      } else if (headersRow[1] === '"Left eye X"' && cachedFile.flag === FileDataType.EyeTracking) {
        this.isEyeTrackingFile = true;
      } else {
        this.snackbar.open('Invalid file or wrong file in wrong slot', 'Dismiss');
        return;
      }

      if (this.isMoraeFile) {
        // const element = document.getElementById('morae-file-name');
        // element.innerHTML = filename;
        this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
      } else {
        // const element = document.getElementById('other-file-name');
        // element.innerHTML = filename;
        if (this.isEmotionFile) {
          if (this.timestampExists) {
            this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
            this.timestampConversion(this.startingTimestamp, this.timestampArr);
            this.replaceTimestamps();

            this.saveToCSV(this.updatedRecords);
          } else {
            this.noTimestampError();
            this.isEmotionFile = false;
          }
        } else if (this.isMuseFile) {
          if (this.timestampExists) {
            this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);
            this.timestampConversion(this.startingTimestamp, this.timestampArr);
            this.replaceTimestamps();

            this.saveToCSV(this.updatedRecords);
          } else {
            this.noTimestampError();
            this.isMuseFile = false;
          }
          // } else if (this.isKeyboardEventFile) {

        } else if (this.isMouseEventFile || this.isKeyboardEventFile) {
          if (this.timestampExists) {
            this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);

            this.timestampConversion(this.startingTimestamp, this.timestampArr);
            this.replaceTimestamps();

            this.saveToCSV(this.updatedRecords);
          } else {
            this.noTimestampError();
            this.isMouseEventFile = false;
          }
        } else if (this.isEyeTrackingFile) {
          if (this.timestampExists) {
            this.records = this.getDataRecordsArrayFromCSVFile(this.csvRecordsArray, headersRow.length);

            this.timestampConversion(this.startingTimestamp, this.timestampArr);
            this.replaceTimestamps();

            this.saveToCSV(this.updatedRecords);
          } else {
            this.noTimestampError();
            this.isMouseEventFile = false;
          }
        } else {
          // throw error - this CSV is not valid (needs to contain Morae, Muse or emotion data)
          this.snackbar.open('Invalid file. Please upload a valid CSV file.', 'Dismiss');
        }
      }
    }
  }

  noTimestampError() {
    this.snackbar.open('No timestamp for recording exists. Make sure to upload CSV with Morae data to get the timestamp.', 'Dismiss');
  }

  // this seems to actually add all the non-header data to the updateRecords array
  replaceTimestamps() {
    for (let i = 0; i < this.records.length; i++) {
      const oldCSVRecord = this.records[i];
      if (this.isEmotionFile && oldCSVRecord instanceof CSVRecordEmotions) {
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.angryColumn, oldCSVRecord.sadColumn, oldCSVRecord.disgustedColumn, oldCSVRecord.surprisedColumn, oldCSVRecord.happyColumn, oldCSVRecord.neutralColumn]);
      } else if (this.isMouseEventFile && oldCSVRecord instanceof CSVRecordMouseEvent) {
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.buttonNameColumn, oldCSVRecord.stateColumn, oldCSVRecord.xColumn, oldCSVRecord.yColumn]);
      } else if (this.isKeyboardEventFile && oldCSVRecord instanceof CSVRecordKeyboardEvent) {
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.buttonNameColumn, oldCSVRecord.stateColumn]);
      } else if (this.isEyeTrackingFile && oldCSVRecord instanceof CSVRecordEyeTrackingEvent) {
        this.updatedRecords.push([this.timestampStringSTArr[i], oldCSVRecord.LeftEyeXColumn, oldCSVRecord.LeftEyeYColumn, oldCSVRecord.RightEyeXColumn, oldCSVRecord.RightEyeYColumn]);
      } else if (this.isMuseFile && oldCSVRecord instanceof CSVRecordMuse) {
        this.updatedRecords.push([
          this.timestampStringSTArr[i],
          parseFloat(oldCSVRecord.deltaTP9Column),
          parseFloat(oldCSVRecord.deltaAF7Column),
          parseFloat(oldCSVRecord.deltaAF8Column),
          parseFloat(oldCSVRecord.deltaTP10Column),
          parseFloat(oldCSVRecord.thetaTP9Column),
          parseFloat(oldCSVRecord.thetaAF7Column),
          parseFloat(oldCSVRecord.thetaAF8Column),
          parseFloat(oldCSVRecord.thetaTP10Column),
          parseFloat(oldCSVRecord.alphaTP9Column),
          parseFloat(oldCSVRecord.alphaAF7Column),
          parseFloat(oldCSVRecord.alphaAF8Column),
          parseFloat(oldCSVRecord.alphaTP10Column),
          parseFloat(oldCSVRecord.betaTP9Column),
          parseFloat(oldCSVRecord.betaAF7Column),
          parseFloat(oldCSVRecord.betaAF8Column),
          parseFloat(oldCSVRecord.betaTP10Column),
          parseFloat(oldCSVRecord.gammaTP9Column),
          parseFloat(oldCSVRecord.gammaAF7Column),
          parseFloat(oldCSVRecord.gammaAF8Column),
          parseFloat(oldCSVRecord.gammaTP10Column)
        ]);
      }
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: string[], headerLength: number) {
    // Emotion
    const csvArr: TimestampConverterComponent["records"] = [];
    for (let i = 1; i < csvRecordsArray.length; i++) { // start at 1 to skip the header
      const currentRecord = csvRecordsArray[i].split(',');
      if (currentRecord.length === headerLength) {
        if (this.isEmotionFile) {
          const csvRecord = new CSVRecordEmotions();
          csvRecord.timestampColumn = currentRecord[0].trim();
          csvRecord.angryColumn = currentRecord[1].trim();
          csvRecord.sadColumn = currentRecord[2].trim();
          csvRecord.disgustedColumn = currentRecord[3].trim();
          csvRecord.surprisedColumn = currentRecord[4].trim();
          csvRecord.happyColumn = currentRecord[5].trim();
          csvRecord.neutralColumn = currentRecord[6].trim();
          this.timestampArr.push(csvRecord.timestampColumn);
          csvArr.push(csvRecord);
        } else if (this.isMuseFile) {
          const csvRecord = new CSVRecordMuse();
          csvRecord.timeColumn = currentRecord[0].trim();
          csvRecord.deltaTP9Column = currentRecord[1].trim();
          csvRecord.deltaAF7Column = currentRecord[2].trim();
          csvRecord.deltaAF8Column = currentRecord[3].trim();
          csvRecord.deltaTP10Column = currentRecord[4].trim();

          csvRecord.thetaTP9Column = currentRecord[5].trim();
          csvRecord.thetaAF7Column = currentRecord[6].trim();
          csvRecord.thetaAF8Column = currentRecord[7].trim();
          csvRecord.thetaTP10Column = currentRecord[8].trim();

          csvRecord.alphaTP9Column = currentRecord[9].trim();
          csvRecord.alphaAF7Column = currentRecord[10].trim();
          csvRecord.alphaAF8Column = currentRecord[11].trim();
          csvRecord.alphaTP10Column = currentRecord[12].trim();

          csvRecord.betaTP9Column = currentRecord[13].trim();
          csvRecord.betaAF7Column = currentRecord[14].trim();
          csvRecord.betaAF8Column = currentRecord[15].trim();
          csvRecord.betaTP10Column = currentRecord[16].trim();

          csvRecord.gammaTP9Column = currentRecord[17].trim();
          csvRecord.gammaAF7Column = currentRecord[18].trim();
          csvRecord.gammaAF8Column = currentRecord[19].trim();
          csvRecord.gammaTP10Column = currentRecord[20].trim();

          this.timestampArr.push(csvRecord.timeColumn);
          csvArr.push(csvRecord);
        } else if (this.isMoraeFile) {
          const containsF9 = currentRecord.toString().includes(ScreenRecorderComponent.recordHotkey);
          if (containsF9) {
            this.startingTimestamp = currentRecord.toString().substring(0, 10);
            const element = document.getElementById('timestampText');
            element.innerHTML = 'Start time of recording: ' + this.startingTimestamp;
            this.timestampExists = true;
            this.isMoraeFile = false;
            return;
          }
        } else if (this.isMouseEventFile || this.isKeyboardEventFile) {
          const csvRecord = new CSVRecordMouseEvent();
          const timeStampInMilliseconds = parseFloat(currentRecord[0].trim());
          csvRecord.timeColumn = format(new Date(timeStampInMilliseconds), 'HH:mm:ss.SSS');
          csvRecord.buttonNameColumn = currentRecord[1].trim();
          csvRecord.stateColumn = currentRecord[2].trim();
          if (this.isMouseEventFile) {
            csvRecord.xColumn = parseFloat(currentRecord[3].trim());
            csvRecord.yColumn = parseFloat(currentRecord[4].trim());
          }
          this.timestampArr.push(csvRecord.timeColumn);

          if (csvRecord.timeColumn === '0:00:00.') {
            csvRecord.timeColumn = '0:00:00.0';
          }

          csvArr.push(csvRecord);
        } else if (this.isEyeTrackingFile) {
          const csvRecord = new CSVRecordEyeTrackingEvent();
          csvRecord.timeColumn = currentRecord[0].trim();
          csvRecord.LeftEyeXColumn = parseFloat(currentRecord[1].trim());
          csvRecord.LeftEyeYColumn = parseFloat(currentRecord[2].trim());
          csvRecord.RightEyeXColumn = parseFloat(currentRecord[3].trim());
          csvRecord.RightEyeYColumn = parseFloat(currentRecord[4].trim());

          this.timestampArr.push(csvRecord.timeColumn);
          csvArr.push(csvRecord);

        }
      }
    }
    return csvArr;
  }

  timestampConversion(startTimeOfRecording: string, timeStampArr: string[]) {
    this.parseStartingTimestampAsModel(startTimeOfRecording);
    this.parseTimestampsAsModels(timeStampArr);
    this.standardizeTimestamps(this.startingTSAsModel, this.timestampAsModelArr);
  }

  parseStartingTimestampAsModel(startTimeOfRecording: string) {
    this.startingTSAsModel = new TimeStampModel();
    const startTimeST = startTimeOfRecording.split(':');
    const startTimeSecondsSplitST = startTimeST[2].split('.');
    this.startingTSAsModel.hours = parseFloat(startTimeST[0]);
    this.startingTSAsModel.minutes = parseFloat(startTimeST[1]);
    this.startingTSAsModel.seconds = parseFloat(startTimeSecondsSplitST[0]);
    this.startingTSAsModel.millis = parseFloat(startTimeSecondsSplitST[1]);
  }

  parseTimestampsAsModels(timestampArr: TimestampConverterComponent["timestampArr"]) {
    for (let i = 0; i < timestampArr.length; i++) {
      const timestampAsModel = new TimeStampModel();
      const timestampNS = timestampArr[i].split(':');
      timestampAsModel.hours = parseFloat(timestampNS[0]);
      timestampAsModel.minutes = parseFloat(timestampNS[1]);
      // Round up milliseconds up to standardize with Morae timestamps
      // const roundedTimestampSeconds = timestampNS[3].toFixed(2);
      // const timestampSecondsSplitNS = roundedTimestampSeconds[2].split('.');
      const timestampSecondsSplitNS = timestampNS[2].split('.');
      timestampAsModel.seconds = parseFloat(timestampSecondsSplitNS[0]);
      timestampAsModel.millis = parseFloat(timestampSecondsSplitNS[1]);
      this.timestampAsModelArr.push(timestampAsModel);
    }
  }

  standardizeTimestamps(startingTSAsModel: TimeStampModel, timestampAsModelArr: TimeStampModel[]) {
    const timestampsAsMillisArr = [];
    const timestampsAsSTMillisArr = [];
    // convert starting timestamp into milliseconds
    const millis = startingTSAsModel.millis * 10;
    const secondsAsMillis = startingTSAsModel.seconds * 1000;
    const minutesAsMillis = startingTSAsModel.minutes * 60000;
    const hoursAsMillis = startingTSAsModel.hours * 3600000;
    const startingTimeInMillis = hoursAsMillis + minutesAsMillis + secondsAsMillis + millis;

    // convert timestamps in array into milliseconds
    this.timestampAsModelArr.forEach(element => {
      const millisTS = element.millis;
      const secondsAsMillisTS = element.seconds * 1000;
      const minutesAsMillisTS = element.minutes * 60000;
      const hoursAsMillisTS = element.hours * 3600000;
      const timestampAsMillis = hoursAsMillisTS + minutesAsMillisTS + secondsAsMillisTS + millisTS;
      timestampsAsMillisArr.push(timestampAsMillis);
    });

    // calculate standardized timestamps in millis
    for (let i = 0; i < timestampsAsMillisArr.length; i++) {
      if (i > 0) {
        // const timestampAsSTMillis = (startingTimeInMillis * timestampsAsMillisArr[i]) / timestampsAsMillisArr[0];
        const timestampAsSTMillis = (timestampsAsMillisArr[i] - timestampsAsMillisArr[0]) + startingTimeInMillis;
        timestampsAsSTMillisArr.push(timestampAsSTMillis);
      } else {
        timestampsAsSTMillisArr.push(startingTimeInMillis);
      }
    }

    // create standardized timestamp string
    timestampsAsSTMillisArr.forEach(element => {
      let millisReminder: number = element;
      let hourPortion = '0';
      let minutePortion = '0';
      let secondPortion = '0';
      let millisPortion = '0';
      if (millisReminder / 3600000 >= 1) {
        hourPortion = (millisReminder / 3600000).toString().split('.')[0];
        millisReminder = millisReminder - parseInt(hourPortion) * 3600000;
      }
      if (millisReminder / 60000 >= 1) {
        minutePortion = (millisReminder / 60000).toString().split('.')[0];
        millisReminder = millisReminder - parseInt(minutePortion) * 60000;
      }
      if (parseInt(minutePortion) < 10) {
        minutePortion = '0' + minutePortion;
      }

      if (millisReminder / 1000 >= 1) {
        secondPortion = (millisReminder / 1000).toString().split('.')[0];
        millisReminder = millisReminder - parseInt(secondPortion) * 1000;
      }
      if (parseInt(secondPortion) < 10) {
        secondPortion = '0' + secondPortion;
      }

      // round millis up or down so there's always 0 at end
      if (millisReminder > 0) {
        millisPortion = millisReminder.toString();
      } else {
        // if there's more than 3 digits, panic
        if (millisReminder.toString().length > 3) throw new Error("Millisecond portion of timestamp is too long!");
        const millisReminderAsString: string = millisReminder.toString();
        millisPortion = millisReminderAsString.substring(0, millisReminderAsString.length - 1);
      }
      if (millisPortion.length < 3) {
        millisPortion = millisPortion.padStart(3, '0');
      }
      // millisPortion = millisReminder;
      const timestampStringST = hourPortion + ':' + minutePortion + ':' + secondPortion + '.' + millisPortion;

      this.timestampStringSTArr.push(timestampStringST);
    });
    return this.timestampStringSTArr;
  }

  fileReset() {
    this.records = [];
  }

  saveToCSV(updatedRecords: TimestampConverterComponent["updatedRecords"]) {
    const a = document.createElement('a');
    let headers = [];
    let flag: FileDataType;
    if (this.isEmotionFile) {
      headers = ['time', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral'];
      a.download = emotionFileSynced.defaultFileName;
      this.isEmotionFile = false;
      flag = FileDataType.EmotionDataSynced;

    } else if (this.isKeyboardEventFile) {
      headers = ['Timestamp', 'ButtonName', 'State'];
      a.download = keyboardEventsSyncedFile.defaultFileName;
      this.isKeyboardEventFile = false;
      flag = FileDataType.KeyboardEventsSynced;

    } else if (this.isMouseEventFile) {
      headers = ['Timestamp', 'ButtonName', 'State', 'X', 'Y'];
      a.download = mouseEventsSynced.defaultFileName;
      this.isMouseEventFile = false;
      flag = FileDataType.MouseEventsSynced;

    } else if (this.isEyeTrackingFile) {
      headers = ['Timestamp', 'LeftEyeX', 'LeftEyeY', 'RightEyeX', 'RightEyeY'];
      a.download = eyeTrackingFileSynced.defaultFileName;
      this.isEyeTrackingFile = false;
      flag = FileDataType.EyeTracking;

    } else if (this.isMuseFile) {
      headers = ['time', 'delta_TP9', 'delta_AF7', 'delta_AF8', 'delta_TP10', 'theta_TP9', 'theta_AF7', 'theta_AF8', 'theta_TP10', 'alpha_TP9', 'alpha_AF7', 'alpha_AF8', 'alpha_TP10', 'beta_TP9', 'beta_AF7', 'beta_AF8', 'beta_TP10', 'gamma_TP9', 'gamma_AF7', 'gamma_AF8', 'gamma_TP10'];
      a.download = museFileSynced.defaultFileName;
      this.isMuseFile = false;
      flag = FileDataType.MuseDataSynced;
    }
    if (!headers.length) throw new Error("Tried to save a invalid file with no headers");
    // const headers = ['time', 'angry', 'sad', 'disgusted', 'surprised', 'happy', 'neutral'];
    const csvData = headers + '\n' + updatedRecords.map(item => item.join(',')).join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });

    this.comData.cacheFile(blob, flag);

    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.updatedRecords = [];
    this.timestampArr = [];
    this.timestampAsModelArr = [];
    this.timestampStringSTArr = [];
  }

};

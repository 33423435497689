import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { createTimestamp } from '../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from '../services/communication.service';
import { RecordingUserConfig } from '../shared/types/user-config';
import RecordRTCPromisesHandler from 'recordrtc';
import { cameraFile, screenFile } from '../file-input/request-files-presets';
import RecordRTC from 'recordrtc';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'screen-webcam-recorder',
  templateUrl: './data-recorder.component.html',
  styleUrls: ['./data-recorder.component.scss'],
})
export class ScreenRecorderComponent implements OnInit, OnDestroy {
  private webcamVideo: MediaStream;
  private webcamRecorder: RecordRTCPromisesHandler;
  private logData = '';
  private scrollObs: Observable<Event>;
  private subscriptions: Subscription;
  private scrollX = window.scrollX;
  private scrollY = window.scrollY;
  private startEpoch = 0;
  private userSettings: RecordingUserConfig;
  private readyToRecord = false;
  private static fileExtension = `${screenFile.fileTypes[0]}` as const;
  private static mimeType = `video/${ScreenRecorderComponent.fileExtension}` as const;

  recordingVideo = false;

  static recordHotkey = 'F9';

  constructor(private comData: CommunicationService, private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    // Ask user for access to webcam and microphone
    // navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    document.addEventListener('keydown', this.keydownHandler);
    // Create a throttled scroll listener
    this.scrollObs = fromEvent(window, 'scroll').pipe(
      throttleTime(300),
      tap(this.scrollHandler)
    );

    const userSettingsSub = this.comData.recordingSettings.subscribe((settings) => {
      this.userSettings = settings;
    });

    const isReadyToRecord = this.comData.canStartRecording.subscribe((ready) => {
      this.readyToRecord = ready;
    }
    );

    const isRecordingSub = this.comData.isRecording.subscribe((isRecording) => {
      if (!isRecording && this.recordingVideo) {
        this.stopRecording();
      }
    });

    this.subscriptions = new Subscription();
    this.subscriptions.add(userSettingsSub);
    this.subscriptions.add(isReadyToRecord);
    this.subscriptions.add(isRecordingSub);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.keydownHandler);
    this.stopRecording();
    this.webcamRecorder?.destroy();
  }

  startRecording() {
    if (this.recordingVideo || !this.comData.changeIsRecording(true)) return;

    // stop the recording if any of the streams stop

    this.startEpoch = Date.now();
    this.logData = `${createTimestamp(this.startEpoch)} Recording started\n`;
    this.recordingVideo = true;

    const recordingConfig: RecordRTC.Options | unknown = {
      type: 'video',
      mimeType: ScreenRecorderComponent.mimeType,
      disableLogs: environment.production
    };

    if (this.webcamVideo) {
      if (!this.webcamRecorder) {
        this.webcamRecorder = new RecordRTCPromisesHandler(this.webcamVideo, recordingConfig);
      }
      this.webcamRecorder.startRecording();
    }

    document.addEventListener('keypress', this.keypressHandler);
    document.addEventListener('mousedown', this.clickHandler);
    const scrollSub = this.scrollObs.subscribe();
    if (!this.subscriptions) {
      this.subscriptions = new Subscription();
    }
    this.subscriptions.add(scrollSub);
  }

  stopRecording() {
    if (!this.recordingVideo) return;
    this.recordingVideo = false;
    this.comData.changeIsRecording(false);
    this.logData += `${createTimestamp(this.startEpoch)} Recording has stopped\n`;

    this.webcamRecorder?.stopRecording(() => {
      this.downloadVideo(this.webcamRecorder.toURL(), cameraFile.defaultFileName);
    });

    document.removeEventListener('keypress', this.keypressHandler);
    document.removeEventListener('mousedown', this.clickHandler);

    this.subscriptions?.unsubscribe();
    this.subscriptions = null;
  }

  downloadVideo(url: string, name: string) {
    if (!url) {
      this.snackbar.open("Could not save webcam recording.");
      throw new Error("Was not provided the video URL when trying to upload it.");
    }
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${name}.${ScreenRecorderComponent.fileExtension}`;
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }

  downloadTimestamps() {
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
      this.logData
    )}`;
    downloadLink.download = `log.txt`;
    downloadLink.click();
    this.logData = '';
  }

  private keydownHandler = async (event: KeyboardEvent): Promise<void> => {
    // this has to run before we start recording, so we run it on both hotkeys
    // also ensure that when pressing the recording button, only request access to recordings if we don't have them already
    if (!this.hasRequiredPermissions()) {

      if (this.userSettings.faceRecording) {
        try {
          const userMedia = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          if (userMedia) {
            this.webcamVideo = userMedia;
          }
        } catch (error: unknown) {
          console.error("Webcam recorder error: ", error);
        }
      }

      if (this.userSettings.faceRecording && !this.webcamVideo) this.snackbar.open("Please allow access to webcam video/sound", "Dismiss");

    }
    if (event.key === ScreenRecorderComponent.recordHotkey && this.readyToRecord) {
      if (this.recordingVideo) {
        this.stopRecording();
      } else if (this.hasRequiredPermissions()) {
        this.startRecording();
        // this will send to the other components so that they also start recording
      } else {
        this.snackbar.open(`Failed to ${this.recordingVideo ? "pause" : "start"} recording`, "Dismiss");
      }
    }
  };

  private hasRequiredPermissions() {
    if (this.userSettings.faceRecording && !this.webcamVideo) return false;
    return true;
  }

  private keypressHandler = (e: KeyboardEvent) => {
    const key = e.key === ' ' ? 'empty space' : e.key;
    this.logData += `${createTimestamp(this.startEpoch)} Pressed ${key}\n`;
  };

  private clickHandler = (e: MouseEvent) => {
    switch (e.button) {
      case 0:
        this.logData += `${createTimestamp(this.startEpoch)} left click x:${e.screenX
          }, y:${e.screenY}\n`;
        break;
      case 1:
        this.logData += `${createTimestamp(this.startEpoch)} middle click x:${e.screenX
          }, y:${e.screenY}\n`;
        break;
      case 2:
        this.logData += `${createTimestamp(this.startEpoch)} right click x:${e.screenX
          }, y:${e.screenY}\n`;
        break;
    }
  };

  private scrollHandler = () => {
    this.logData += `${createTimestamp(
      this.startEpoch
    )} scrolled x:${this.scrollX.toFixed(2)}, y:${this.scrollY.toFixed(
      2
    )} -> x:${window.scrollX.toFixed(2)}, y:${window.scrollY.toFixed(2)}\n`;
    this.scrollX = window.scrollX;
    this.scrollY = window.scrollY;
  };
}

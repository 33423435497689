import { FileDataType } from './recorded-data-enums';
import * as AllFilesNamespace from './request-files-presets';

export interface SharedFile {
  defaultFileName: string;
  displayName: string;
  /// Used for identifying the specific kind of file we're dealing with without touching names
  flag: FileDataType;
  fileTypes: string[]; // index 0 is considered default, do not include the "."
}

export interface CachedFile<T = unknown> extends SharedFile {
  file: File;
  parsedData?: T;
}

export interface RequestFile<T = unknown> extends SharedFile {
  callback?: (file: File) => Promise<T>;
  parsedData?: T;
}

export const allFileTemplates: RequestFile[] = Object.keys(AllFilesNamespace)
  .map((key => AllFilesNamespace[key]))
  .sort((a, b) => a.flag - b.flag);

export function getDefaultFileName(file: SharedFile) {
  return `${file.defaultFileName}.${file.fileTypes[0]}`;
}

export function getDefaultFlagName(file: FileDataType) {
  return FileDataType[file];
}

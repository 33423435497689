// headers must correspond to timestamp-converter.component.ts output, as it is manually set there

export class CSVRecordEmotions {
  public timestampColumn: string;
  public angryColumn: string;
  public sadColumn: string;
  public disgustedColumn: string;
  public surprisedColumn: string;
  public happyColumn: string;
  public neutralColumn: string;
}

export class CSVRecordMuse {
  public timeColumn: string;
  public timeAsSeconds: number;

  public deltaTP9Column: string;
  public deltaAF7Column: string;
  public deltaAF8Column: string;
  public deltaTP10Column: string;

  public thetaTP9Column: string;
  public thetaAF7Column: string;
  public thetaAF8Column: string;
  public thetaTP10Column: string;

  public alphaTP9Column: string;
  public alphaAF7Column: string;
  public alphaAF8Column: string;
  public alphaTP10Column: string;

  public betaTP9Column: string;
  public betaAF7Column: string;
  public betaAF8Column: string;
  public betaTP10Column: string;

  public gammaTP9Column: string;
  public gammaAF7Column: string;
  public gammaAF8Column: string;
  public gammaTP10Column: string;
}

export class CSVProjectData {
  public elapsedTime: string;
  public timeInSeconds: number;
  public recording: string;
  public task: string;
  public event: string;
  public details: string;
  public application: string;
  public owner: string;
  public notes: string;
  public url: string;
  public pageTitle: string;
  public title: string;
  public score: string;
}

export class MuseDataPerSecond {
  public timeAsSeconds: number;

  public deltaTP9: string[] = [];
  public deltaAF7: string[] = [];
  public deltaAF8: string[] = [];
  public deltaTP10: string[] = [];

  public thetaTP9: string[] = [];
  public thetaAF7: string[] = [];
  public thetaAF8: string[] = [];
  public thetaTP10: string[] = [];

  public alphaTP9: string[] = [];
  public alphaAF7: string[] = [];
  public alphaAF8: string[] = [];
  public alphaTP10: string[] = [];

  public betaTP9: string[] = [];
  public betaAF7: string[] = [];
  public betaAF8: string[] = [];
  public betaTP10: string[] = [];

  public gammaTP9: string[] = [];
  public gammaAF7: string[] = [];
  public gammaAF8: string[] = [];
  public gammaTP10: string[] = [];
}

export class MuseAveragesPerSecond {
  public timeAsSeconds: number;

  public deltaTP9: number;
  public deltaAF7: number;
  public deltaAF8: number;
  public deltaTP10: number;

  public thetaTP9: number;
  public thetaAF7: number;
  public thetaAF8: number;
  public thetaTP10: number;

  public alphaTP9: number;
  public alphaAF7: number;
  public alphaAF8: number;
  public alphaTP10: number;

  public betaTP9: number;
  public betaAF7: number;
  public betaAF8: number;
  public betaTP10: number;

  public gammaTP9: number;
  public gammaAF7: number;
  public gammaAF8: number;
  public gammaTP10: number;
}

export class MuseBaselineData {

  public deltaTP9: number[] = [];
  public deltaAF7: number[] = [];
  public deltaAF8: number[] = [];
  public deltaTP10: number[] = [];

  public thetaTP9: number[] = [];
  public thetaAF7: number[] = [];
  public thetaAF8: number[] = [];
  public thetaTP10: number[] = [];

  public alphaTP9: number[] = [];
  public alphaAF7: number[] = [];
  public alphaAF8: number[] = [];
  public alphaTP10: number[] = [];

  public betaTP9: number[] = [];
  public betaAF7: number[] = [];
  public betaAF8: number[] = [];
  public betaTP10: number[] = [];

  public gammaTP9: number[] = [];
  public gammaAF7: number[] = [];
  public gammaAF8: number[] = [];
  public gammaTP10: number[] = [];
}

export class MuseAverages {
  public deltaTP9: number;
  public deltaAF7: number;
  public deltaAF8: number;
  public deltaTP10: number;

  public thetaTP9: number;
  public thetaAF7: number;
  public thetaAF8: number;
  public thetaTP10: number;

  public alphaTP9: number;
  public alphaAF7: number;
  public alphaAF8: number;
  public alphaTP10: number;

  public betaTP9: number;
  public betaAF7: number;
  public betaAF8: number;
  public betaTP10: number;

  public gammaTP9: number;
  public gammaAF7: number;
  public gammaAF8: number;
  public gammaTP10: number;
}

export class AnalyzedMuseDataPerSecond {
  public timeAsSeconds: number;

  public attentionDeltaTP9: number;
  public attentionDeltaAF7: number;
  public attentionDeltaAF8: number;
  public attentionDeltaTP10: number;
  public attentionAverages: number;

  public arousalGammaTP9: number;
  public arousalGammaAF7: number;
  public arousalGammaAF8: number;
  public arousalGammaTP10: number;
  public arousalAverages: number;

  public avoid: number;
  public approach: number;

}

export class ColumnAveragesPerTask {
  public averageOfAttentionAverages: number;
  public averageOfArousalAverages: number;
  public avoidAverages: number;
  public approachAverages: number;
}

export class Task {
  public taskNumber: number;
  public secondBasedData: AnalyzedMuseDataPerSecond[];
  public averages: ColumnAveragesPerTask;
}

export class CSVRecordEmotionsAnalyzed {
  public timestampColumn: string;
  public timeStampsAsSecondsColumn: number;
  public angryColumn: string;
  public sadColumn: string;
  public disgustedColumn: string;
  public surprisedColumn: string;
  public happyColumn: string;
  public neutralColumn: string;
  public strongestEmotionColumn: string;
  public secondStrongestEmotionColumn: string;
  public thirdStrongestEmotionColumn: string;
}

export class Timestamp {
  public timeColumn: string;
  public eventShown = false;
}

export class CSVRecordMouseMovement extends Timestamp {
  public xColumn: number;
  public yColumn: number;
}

export class CSVRecordMouseEvent extends Timestamp {
  public buttonNameColumn: string;
  public stateColumn: string;
  public xColumn: number;
  public yColumn: number;
}

export class CSVRecordEyeTrackingEvent extends Timestamp {
  public LeftEyeXColumn: number;
  public LeftEyeYColumn: number;
  public RightEyeXColumn: number;
  public RightEyeYColumn: number;

}

export class CSVRecordKeyboardEvent extends Timestamp {
  buttonNameColumn: string;
  stateColumn: string;
}

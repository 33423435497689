import CanvasDrawn, { CanvasCircle, CanvasLine, CanvasTriangle } from '../redrawing-canvas/canvas-drawn-model-class';
import { CSVRecordEyeTrackingEvent, CSVRecordMouseEvent, Timestamp } from '../shared/types/csv-model';

export class TrackerEventHandler<T extends Timestamp> {

  constructor(
    public trackedEvents: T[],
    public canvasDrawn: CanvasDrawn[],
    currentTime = 0,
    readonly setCanvasCallback: () => Promise<CanvasDrawn[]>
  ) {
    this.currentTime = currentTime;
    if (!this.isValidEvents(trackedEvents)) {
      throw new Error('invalid events provided to TrackerEventHandler: ' + trackedEvents);
    }
  }

  protected trackingName = 'tracker'; // what are we tracking?
  protected lineColor = 'blue';
  protected eventType = Timestamp; // is it possible to directly reference the generic type T here?
  protected createShape = CanvasDrawn;
  protected initialRadius = 10;
  protected eventUpdateDelay = 1000;

  private isPaused = false;
  private lastDrawnShape: CanvasDrawn;
  private lastTrackedEvent: T;
  private decayTime = 2;
  private logging = false;

  currentTime = 0;
  timer: NodeJS.Timeout;

  public startTimer() {
    this.isPaused = false;
    this.nextTrackerEvent();
  }

  public stopTimer() {
    this.isPaused = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  // re-generate all shapes that should be active now after seeking the video
  public async updateToTime(time: number) {
    // this.currentTime = time;
    this.canvasDrawn = [];
    // // find all events that are decayTime + currentTime and redraw them
    const toDraw = this.trackedEvents.filter((element) => Number(element.timeColumn) + this.decayTime >= this.currentTime);
    this.trackedEvents.forEach((element) => element.eventShown = false); // temp!!
    toDraw.forEach((element) => this.handleTrackerEvent(element));
    clearTimeout(this.timer);
    this.timer = null;
    this.nextTrackerEvent();
  }

  protected isValidEvents(events: T[]) {
    if (!events || !events.length)
      return false;
    return events.every((event) => (event instanceof this.eventType));
  }

  //TODO, consider killing eventShown
  protected nextTrackerEvent() {
    if (!this.trackedEvents) return;
    // find the NEXT eye track event after currentTime
    const nextTrackEvent = this.trackedEvents.find(eventRecord => !eventRecord.eventShown && Number(eventRecord.timeColumn) >= this.currentTime);
    if (!nextTrackEvent || this.isPaused) return;

    const msToNextEvent = (Number(nextTrackEvent.timeColumn) - this.currentTime) * this.eventUpdateDelay;

    // recursively calls itself until we reach the last eye track event
    this.timer = setTimeout(() => {
      this.handleTrackerEvent(nextTrackEvent);
      this.nextTrackerEvent();
    }, msToNextEvent);
  }

  private async handleTrackerEvent(timestamp: T) {
    if (!timestamp) throw new Error('no eye track timestamp provided to handleEyeTrackerEvent');

    let radiusSize = this.initialRadius;
    let line: CanvasDrawn;
    const toDraw: CanvasDrawn[] = [];

    this.log('checking timestamp:', timestamp.timeColumn, 'current time:', this.currentTime);

    // where to draw the eye tracker circle and line
    const x = this.getXAxis(timestamp);
    const y = this.getYAxis(timestamp);

    const foundConflictingDrawn = this.removeConflictingDrawn(x, y, radiusSize);

    if (foundConflictingDrawn?.length) {
      radiusSize = this.onOverlapDraw(x, y, foundConflictingDrawn);

    } else if (this.lastTrackedEvent && this.lastDrawnShape) {
      line = this.connectingDrawn(x, y);
      if (line) toDraw.push(line);
    }

    timestamp.eventShown = true;
    this.log(`drawing ${this.trackingName} mark at `, x, y, ' with radius ', radiusSize);

    this.lastDrawnShape = this.drawShape(x, y, radiusSize, this.decayTime + this.currentTime);
    this.lastTrackedEvent = timestamp;
    this.log('decaying at:', this.decayTime + this.currentTime, 'current time:', this.currentTime);

    line?.relatedShapes.push(this.lastDrawnShape);
    this.lastDrawnShape.relatedShapes.push(line);
    toDraw.push(this.lastDrawnShape);
    this.canvasDrawn = [...this.canvasDrawn, ...toDraw]; // the parent component will have to combine other canvasDrawn and it can't do that if we provide it in the callback args
    this.setCanvasCallback().then((canvasDrawn) => this.canvasDrawn = canvasDrawn);
  }

  protected connectingDrawn(x: number, y: number) {
    const lineX2 = this.getXAxisNumber(this.lastTrackedEvent);
    const lineY2 = this.getYAxisNumber(this.lastTrackedEvent);
    const line = this.drawLine(x, y, lineX2, lineY2, this.lineColor, this.lastDrawnShape.deleteIn);
    this.log('drawing line from ', x, y, ' to ', lineX2, lineY2);
    return line;
  }

  protected onOverlapDraw(x: number, y: number, conflictingDrawn: CanvasDrawn[]): number {
    const lookTimeRadius = 5;
    // color = foundConflictingDrawn[0].color;
    const allRadiuses = conflictingDrawn.map((element) => element.radius);
    const largestRadius = Math.max(...allRadiuses);
    const radiusSize = largestRadius + lookTimeRadius;
    conflictingDrawn.push(...this.removeConflictingDrawn(x, y, radiusSize)); // radiusSize changes, so we have to re-check for collisions
    // TODO figure out a way to move the old lines to the new center of the circle
    // maybe by checking if we have a connection and then manually moving that to the new connection?

    // we have to decay the old lines and allow new ones to be drawn, or it won't point to the center of the circle
    // const relatedShapes = foundConflictingDrawn.map((element) => element.relatedShapes).flat();
    // const connectedLines = relatedShapes.filter((element) => element instanceof CanvasLine);
    // connectedLines.forEach((element) => element.decay());

    this.log(`${this.trackingName} has been same place for some amount of time, increasing radius to `, radiusSize);
    return radiusSize;
  }

  protected drawShape(x: number, y: number, radius: number, deleteIn: number) {
    return new this.createShape(x, y, this.lineColor, radius, deleteIn);
  }

  private getXAxisNumber(timestamp: T) {
    const possibleNumber = Number(this.getXAxis(timestamp));
    if (isNaN(possibleNumber)) throw new Error('x axis is not a number');
    return possibleNumber;
  }

  private getYAxisNumber(timestamp: T) {
    const possibleNumber = Number(this.getYAxis(timestamp));
    if (isNaN(possibleNumber)) throw new Error('y axis is not a number');
    return possibleNumber;
  }

  protected getXAxis(timestamp: T) {
    return null;
  }

  protected getYAxis(timestamp: T) {
    return null;
  }

  protected removeConflictingDrawn(x = 0, y = 0, radius = 5, type: typeof CanvasDrawn = CanvasCircle) {
    // check if the radius of any drawn shape intersects with the eye track event, make sure to check element radius as well
    const matchingEyeDrawn = this.canvasDrawn.filter((element) =>
      element instanceof type
      && Math.abs(element.x - x) < radius + element.radius
      && Math.abs(element.y - y) < radius + element.radius
    );
    matchingEyeDrawn?.forEach((element) => element.decay());
    return matchingEyeDrawn;
  }

  protected drawLine(x1: number, y1: number, x2: number, y2: number, color = 'red', deleteIn = 0) {
    const line = new CanvasLine(x1, y1, x2, y2, color, deleteIn);
    return line;
  }

  private log(...args) {
    if (this.logging) {
      console.log(...args);
    }
  }
}

export class EyeTrackerEventHandler extends TrackerEventHandler<CSVRecordEyeTrackingEvent> {
  protected trackingName = 'eye';
  protected lineColor = 'red';
  protected eventType = CSVRecordEyeTrackingEvent;
  protected createShape = CanvasCircle;

  protected getXAxis(timestamp: CSVRecordEyeTrackingEvent) {
    return timestamp.LeftEyeXColumn;
  }

  protected getYAxis(timestamp: CSVRecordEyeTrackingEvent) {
    return timestamp.LeftEyeYColumn;
  }
}

export class MouseEventHandler extends TrackerEventHandler<CSVRecordMouseEvent> {
  protected trackingName = 'mouse';
  protected lineColor = 'orange';
  protected initialRadius = 40;
  protected eventType = CSVRecordMouseEvent;
  protected createShape = CanvasTriangle;

  protected getXAxis(timestamp: CSVRecordMouseEvent) {
    return timestamp.xColumn;
  }

  protected getYAxis(timestamp: CSVRecordMouseEvent) {
    return timestamp.yColumn;
  }
  // don't care about conflicts here
  protected removeConflictingDrawn(x = 0, y = 0, radius = 5, type: typeof CanvasDrawn = CanvasCircle): CanvasDrawn[] {
    return [];
  }

  protected connectingDrawn() {
    return null;
  }
}

<mat-toolbar class="footer" style="z-index: 10;">
  <div class="container">
    <p style="display: flex; gap: 1rem">
      <a href="https://preview.twn.ee/neurouxdemo">Visit NeuroUX Demo</a>
      <a [href]="getConsoleAppURL()">
        Download Console Application
      </a>
    </p>
    <p>
      This software uses code of
      <a href="http://ffmpeg.org">FFmpeg</a> licensed under the
      <a href="http://www.gnu.org/licenses/old-licenses/lgpl-2.1.html">LGPLv2.1</a> and its source can be downloaded
      <a [href]="getFFmpegSourceURL()">here</a>
    </p>
  </div>
  <mat-toolbar>
  </mat-toolbar>
</mat-toolbar>

<div id="present-data-container">
  <div style="display: flex; flex-direction: column; align-items: center;">
    <div>
      <h1>Data Presentation</h1>
      <p>
        Present and visualize the data you have recorded. <br>
        Upload the video file you want to present and the data files you want to visualize.
        Circles and lines showcase eye tracking, and triangles showcase mouse clicks
      </p>
    </div>
    <app-file-input [requestFileFlags]="requestInputs" />
  </div>
  <section id="video-upload-component"
    style="margin-bottom: 5rem; display: flex; justify-content: center; align-items: center; flex-direction: column;">

    <app-keyboard-present [keyboardEvents]="keyboardEventRecords" />

    <app-steppable-video #videoPlayer [videoUrl]="videoUrl" (playerReady)="onVideoReady($event)" (ended)="onVideoEnd()">
      <app-tracker-data-display class="overlay" [mouseEvents]="mouseEventRecords"
        [eyeEvents]="eyeTrackingEventRecords" />
    </app-steppable-video>
  </section>
</div>
